import { useState, useEffect } from 'react';

export const useIsMobile = (threshold: number = 768) => {
    const [isMobile, setIsMobile] = useState<boolean>(true);

    const handleResize = () => {
        const _isMobile = window.innerWidth < threshold;
        if(_isMobile !== isMobile) {
            setIsMobile(_isMobile);
        }
    }

    useEffect(() => {
        handleResize();

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
        
        // eslint-disable-next-line
    }, [isMobile]);
    
    return isMobile;
}
