import { config } from '../config';
import { QuotePosition } from '../entity';
import { HttpMethods } from '../shared/constants';
import { ApiClient, ApiRequest, ApiResponse } from './ApiClient';

export const QuotesService = {
    ListQuotes: async (position: QuotePosition, skip?: number): Promise<ApiResponse> => {
        const query = new URLSearchParams();
        query.append('position', position);
        if(skip)
            query.append('skip', String(skip));
        const queryString = query.toString();
        
        const request = new ApiRequest({
            method: HttpMethods.GET,
            resource: `/quotes${queryString ? `?${queryString}` : ''}`,
            auth: config.Auth.Token(),
        });

        return await ApiClient.ExecuteRequest(request);
    },

    SetPrice: async (quoteId: string, price: number): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.PATCH,
            resource: `/quotes/${quoteId}/price`,
            auth: config.Auth.Token(),
            body: { price },
        });

        return await ApiClient.ExecuteRequest(request);
    },

    ArchiveQuote: async (quoteId: string): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.PATCH,
            resource: `/quotes/${quoteId}/archive`,
            auth: config.Auth.Token(),
        });

        return await ApiClient.ExecuteRequest(request);
    },

    SetQuoteInfo: async (quoteId: string, name: string, email: string, phone: string): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.PATCH,
            resource: `/quotes/${quoteId}/info`,
            auth: config.Auth.Token(),
            body: { name, email, phone },
        });

        return await ApiClient.ExecuteRequest(request);
    },

    CalculateQuoteRoute: async (quoteId: string): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.PATCH,
            resource: `/quotes/${quoteId}/route`,
            auth: config.Auth.Token(),
        });

        return await ApiClient.ExecuteRequest(request);
    },
} as const;
