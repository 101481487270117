import { IconAlertTriangle, IconArchive, IconCheckCircle, IconCornerRightDown, IconCornerRightUp, IconStar, IconTrash, IconUser } from '../shared';
import { Broker } from './Broker';
import { Trip } from './Trip';

export enum OrderStatus {
    New = 'new',
    Assigned = 'assigned',
    PickedUp = 'picked_up',
    Delivered = 'delivered',
    Archived = 'archived',
    Deleted = 'deleted',
}

export namespace OrderStatus {
    export const Parse = (status: string): OrderStatus | undefined => {
        if((Object.values(OrderStatus)).includes(status as any)) {
            for(const [key, value] of Object.entries(OrderStatus))
                if(value === status)
                    return (OrderStatus as any)[key];
        }
        return undefined;
    }

    export const Info = (status: OrderStatus): { label: string, color: string, icon: JSX.Element } => {
        switch(status) {
            case OrderStatus.New:
                return { label: 'New', color: 'blue-light', icon: IconStar };
            case OrderStatus.Assigned:
                return { label: 'Assigned', color: 'blue', icon: IconUser };
            case OrderStatus.PickedUp:
                return { label: 'Picked up', color: 'yellow', icon: IconCornerRightUp };
            case OrderStatus.Delivered:
                return { label: 'Delivered', color: 'green', icon: IconCornerRightDown };
            case OrderStatus.Archived:
                return { label: 'Archived', color: 'grey', icon: IconArchive };
            case OrderStatus.Deleted:
                return { label: 'Deleted', color: 'red', icon: IconTrash };
        }
    }
}

export enum OrderInspectionTypes {
    Aiag = 'aiag',
    Stadard = 'standard',
    Advanced = 'advanced',
}

export class OrderVehicle {
    id: number;
    make: string;
    model: string;
    year: number;
    color: string;
    vin: string;
    type: string;
    lotNumber: string;
    isInoperable: boolean;
    requiresEnclosedTrailer: boolean;
    photos: { url: string }[];

    constructor(init?: Partial<OrderVehicle>) {
        Object.assign(this, init);
    }

    public static Make(sd: any): OrderVehicle {
        return new OrderVehicle({
            id: sd.id,
            make: sd.make,
            model: sd.model,
            year: sd.year,
            color: sd.color,
            vin: sd.vin,
            type: sd.type,
            lotNumber: sd.lotNumber,
            isInoperable: sd.isInoperable,
            requiresEnclosedTrailer: sd.requiresEnclosedTrailer,
            photos: sd.photos.map((p: any) => ({ Url: p.url }) ),
        });
    }
}

export class OrderPayment {
    terms: string;
    notes: string;
    paidAt: any;
    invoice: { id: string, sentAt: any };

    constructor(init?: Partial<OrderPayment>) {
        Object.assign(this, init);
    }

    public static Make(sd: any): OrderPayment {
        return new OrderPayment({
            terms: sd.terms,
            notes: sd.notes,
            paidAt: sd.paidAt,
            invoice: {
                id: sd.invoice.id,
                sentAt: sd.invoice.sentAt,
            }
        });
    }
}

export class OrderCustomer {
    name: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    contact: { name: string, phone: string, email: string, fax: string };

    constructor(init?: Partial<OrderCustomer>) {
        Object.assign(this, init);
    }

    public static Make(sd: any): OrderCustomer {
        return new OrderCustomer({
            name: sd.name,
            address: sd.address,
            city: sd.city,
            state: sd.state,
            zip: sd.zip,
            contact: {
                name: sd.contact.name,
                phone: sd.contact.phone,
                email: sd.contact.email,
                fax: sd.contact.fax,
            }
        });
    }
}

export enum OrderEndType {
    Dealer = 'dealer',
    Private = 'private',
    Auction = 'auction',
    Others = 'others',
}

export namespace OrderEndType {
    export const Info = (type: OrderEndType | null): { label: string } => {
        switch(type) {
            case OrderEndType.Dealer:
                return { label: 'Dealer' };
            case OrderEndType.Private:
                return { label: 'Private' };
            case OrderEndType.Auction:
                return { label: 'Auction' };
            case OrderEndType.Others:
                return { label: 'Others' };
            default:
                return { label: '---' };
        }
    }
}

export enum OrderEndOption {
    Pickup = 'pickup',
    Delivery = 'delivery',
}

export enum OrderPosition {
    Pending = 'pending',
    Ready = 'ready',
}

export namespace OrderPosition {
    export const Info = (position: OrderPosition | null): { label: string, color: string, icon: JSX.Element | null } => {
        switch(position) {
            case OrderPosition.Pending:
                return { label: 'Pending', color: 'yellow', icon: IconAlertTriangle };
            case OrderPosition.Ready:
                return { label: 'Ready', color: 'green', icon: IconCheckCircle };
            default:
                return { label: '---', color: 'gray', icon: null };
        }
    }
}

export class OrderEnd {
    notes: string;
    scheduledAt: Date | null;
    completedAt: Date | null;
    venue: {
        name: string;
        address: string;
        city: string;
        state: string;
        zip: string;
        contact: {
            name: string;
            phone: string;
        }
    }

    constructor(init?: Partial<OrderEnd>) {
        Object.assign(this, init);
    }

    public static Make(sd: any): OrderEnd {
        return new OrderEnd({
            notes: sd.notes,
            scheduledAt: sd.scheduledAt ? new Date(sd.scheduledAt) : null,
            completedAt: sd.completedAt ? new Date(sd.completedAt) : null,
            venue: {
                name: sd.venue.name,
                address: sd.venue.address,
                city: sd.venue.city,
                state: sd.venue.state,
                zip: sd.venue.zip,
                contact: {
                    name: sd.venue.contact.name,
                    phone: sd.venue.contact.phone,
                },
            },
        });
    }
}

export class Order {
    orderId: string;
    sdId: string;
    loadId: string;

    status: OrderStatus;
    prevStatus: OrderStatus | null;
    
    carrierName: string;
    driverId: string;
    number: string;
    instructions: string;
    buyerNumber: string;
    price: number;
    brokerFee: any;

    vehicles: OrderVehicle[];

    pdfBolUrl: string;
    inspectionType: OrderInspectionTypes;

    payment: OrderPayment;

    customer: OrderCustomer;

    pickup: OrderEnd;
    pickupType: OrderEndType | null;
    delivery: OrderEnd;
    deliveryType: OrderEndType | null;

    pickupPosition: OrderPosition;
    pickupPositionNotes: string;
    deliveryPosition: OrderPosition;
    deliveryPositionNotes: string;

    assignedTo: string | null;
    assignedToName: string | null;

    brokerId: string | null;
    broker: Broker | null;

    contacts: OrderContact[] | null;

    trip: Trip | null;

    constructor(init?: Partial<Order>) {
        Object.assign(this, init);
    }

    public static Make(sd: any): Order {
        return new Order({
            orderId: sd.orderId,
            sdId: String(sd.sdId),
            loadId: sd.loadId,

            status: sd.status,
            prevStatus: sd.prevStatus,

            carrierName: sd.carrierName,
            driverId: sd.driverId,
            number: sd.number,
            instructions: sd.instructions,
            buyerNumber: sd.buyerNumber,
            price: Number(sd.price),
            brokerFee: sd.brokerFee,

            vehicles: sd.vehicles.map((v: any) => OrderVehicle.Make(v)),
            pdfBolUrl: sd.pdfBolUrl,
            inspectionType: sd.inspectionType,
            payment: OrderPayment.Make(sd.payment),
            customer: OrderCustomer.Make(sd.customer),

            pickup: OrderEnd.Make(sd.pickup),
            pickupType: sd.pickupType,
            delivery: OrderEnd.Make(sd.delivery),
            deliveryType: sd.deliveryType,

            pickupPosition: sd.pickupPosition,
            pickupPositionNotes: sd.pickupPositionNotes,
            deliveryPosition: sd.deliveryPosition,
            deliveryPositionNotes: sd.deliveryPositionNotes,

            assignedTo: sd.assignedTo,
            assignedToName: sd.assignedToName ?? null,

            brokerId: sd.brokerId ?? null,
            broker: sd.broker ? Broker.Make(sd.broker) : null,

            contacts: sd.contacts ? sd.contacts.map((c: any) => OrderContact.Make(c)) : null,

            trip: sd.trip,
        });
    }

    public Contact(type: OrderContactType | null): OrderContact | undefined {
        return this.contacts?.find(c => c.type === type);
    }

    public SetContact(contact: OrderContact) {
        if(this.contacts?.length) {
            this.contacts = this.contacts.map(c => {
                if(c.type === contact.type)
                    c = contact;
                return c;
            });
        }
        else {
            if(!this.contacts)
                this.contacts = [];
            this.contacts.push(contact);
        }
    }

    public UpdateOrder(order: Order) {
        Object.assign(this, order);
    }
}

export enum OrderContactType {
    Origin = 'origin',
    Destination = 'destination',
}

export class OrderContact {
    name: string;
    phone: string;
    email: string;
    type: OrderContactType;

    constructor(init?: Partial<OrderContact>) {
        Object.assign(this, init);
    }

    public static Make(obj: any): OrderContact {
        const contact = new OrderContact();
        Object.assign(contact, obj);
        return contact;
    }
}

export class OrderStatusCount {
    status: OrderStatus;
    count: number;

    constructor(init?: Partial<OrderStatusCount>) {
        Object.assign(this, init);
    }

    public static Make(obj: any): OrderStatusCount {
        return new OrderStatusCount({
            status: obj['status'],
            count: Number(obj['count']),
        });
    }
}

export class OrdersSummary {
    statusCount: OrderStatusCount[];

    constructor(init?: Partial<OrdersSummary>) {
        Object.assign(this, init);
    }

    public static Make(obj: any): OrdersSummary {
        return new OrdersSummary({
            statusCount: obj.statusCount?.map((sc: any) => OrderStatusCount.Make(sc)) ?? [],
        });
    }
}

export enum OrderFilters {
    LoadId = 'load-id',
}
