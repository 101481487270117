import { Card } from './Payment';

export enum TransactionResult {
    Paid = 'paid',
    Error = 'error',
};

export class Transaction {
    transactionId: string;
    message: string;
    result: TransactionResult;

    amount: number;
    card: Card;

    createdAt: Date;

    constructor(init: Partial<Transaction>) {
        Object.assign(this, init);
    }

    public static Make(obj: any): Transaction {
        return new Transaction({
            transactionId: obj.transactionId,
            message: obj.message,
            result: obj.result,

            amount: obj.amount,
            card: new Card({
                number: obj.card.number,
                brand: obj.card.brand,
            }),

            createdAt: new Date(obj.createdAt),
        });
    }
}
