export enum VehicleSize {
    Small = 'small',
    Medium = 'medium',
    Large = 'large',
}

export class Vehicle {
    size: VehicleSize;

    year: number;
    make: string;
    model: string;
    running: boolean;

    constructor(init?: Partial<Vehicle>) {
        Object.assign(this, init);
    }
}
