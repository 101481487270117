export class Broker {
    brokerId: string;

    name: string;
    slug: string;
    email: string;
    phone: string;

    constructor(init?: Partial<Broker>) {
        Object.assign(this, init);
    }

    public static Make(obj: any): Broker {
        return new Broker({
            brokerId: obj.brokerId,

            name: obj.name,
            slug: obj.slug,
            email: obj.email,
            phone: obj.phone,
        });
    }
}
