import { config } from '../config';
import { Coupon } from '../entity';
import { HttpMethods } from '../shared/constants';
import { ApiClient, ApiRequest, ApiResponse } from './ApiClient';

export const CouponsService = {
    CreateCoupon: async (coupon: Coupon): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.POST,
            resource: '/coupons',
            body: { coupon },
            auth: config.Auth.Token(),
        });

        return await ApiClient.ExecuteRequest(request);
    },

    ListCoupons: async (): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.GET,
            resource: '/coupons',
            auth: config.Auth.Token(),
        });

        return await ApiClient.ExecuteRequest(request);
    },

    EditCoupon: async (coupon: Coupon): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.PATCH,
            resource: '/coupons',
            body: { coupon },
            auth: config.Auth.Token(),
        });

        return await ApiClient.ExecuteRequest(request);
    },
} as const;
