import { config } from '../config';
import { MessageFilters, OrderContactType } from '../entity';
import { HttpMethods } from '../shared/constants';
import { ApiClient, ApiRequest, ApiResponse } from './ApiClient';

export interface SendOrderMessageParams {
    OrderId: string;
    TripId?: string;
    ContactType: OrderContactType;
    Message: string;
}

interface SendMessageParams {
    Phone: string;
    Body: string;
    Media: string[];
}

interface EditConversationNameParams {
    Phone: string;
    Name: string;
}

interface SendBulkMessagesParams {
    TripId?: string;
    OrdersIds: string[];
    ContactType: OrderContactType;
    Message: string;
}

export const MessagesService = {
    SendOrderMessage: async (params: SendOrderMessageParams): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.POST,
            resource: '/messages/order',
            auth: config.Auth.Token(),
            body: { ...params },
        });

        return await ApiClient.ExecuteRequest(request);
    },

    ListMessagesSummary: async (skip: number): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.GET,
            resource: `/messages?skip=${skip}`,
            auth: config.Auth.Token(),
        });

        return await ApiClient.ExecuteRequest(request);
    },

    GetConversation: async (phone: string): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.GET,
            resource: `/messages/${phone}`,
            auth: config.Auth.Token(),
        });

        return await ApiClient.ExecuteRequest(request);
    },

    SendMessage: async (params: SendMessageParams): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.POST,
            resource: '/messages',
            auth: config.Auth.Token(),
            body: { ...params },
        });

        return await ApiClient.ExecuteRequest(request);
    },

    GetUnreadCount: async (): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.GET,
            resource: '/messages/unread-count',
            auth: config.Auth.Token(),
        });

        return await ApiClient.ExecuteRequest(request);
    },

    EditConversationName: async (params: EditConversationNameParams): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.PATCH,
            resource: '/messages/name',
            auth: config.Auth.Token(),
            body: { ...params },
        });

        return await ApiClient.ExecuteRequest(request);
    },

    FilterMessages: async (context: MessageFilters, term: string): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.GET,
            resource: `/messages/filter?context=${context}&term=${term}`,
            auth: config.Auth.Token(),
        });

        return await ApiClient.ExecuteRequest(request);
    },

    SendMessages: async (params: SendBulkMessagesParams): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.POST,
            resource: '/messages/messages',
            auth: config.Auth.Token(),
            body: { ...params },
        });

        return await ApiClient.ExecuteRequest(request);
    },
}
