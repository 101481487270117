import { format, compareAsc, startOfDay } from 'date-fns';

export const UtilDate = {
    isGreaterThan: (date: Date, compareTo: Date): boolean => {
        const _date = UtilDate.isDate(date) ? date : new Date(date);
        const _compareTo = UtilDate.isDate(compareTo) ? compareTo : new Date(compareTo);
        return _date > _compareTo;
    },

    isDate: (date: any): boolean => {
        return typeof date.getMonth === 'function';
    },

    isValid: (date: any): boolean => {
        const _date = new Date(date);
        return !isNaN(_date.getDate());
    },

    FormatDate_MM_DD_YY: (date: Date): string => {
        return format(date, 'MM/dd/yy');
    },

    FormatDate_MM_DD_YYYY: (date: Date): string => {
        return format(date, 'MM/dd/yyyy');
    },

    FormatDate_MM_DD_YY_HH_II_A: (date: Date): string => {
        return `${UtilDate.FormatDate_MM_DD_YY(date)} ${format(date, 'hh:mm a')}`;
    },

    IsAfterToday: (date: Date): number => {
        const _date = startOfDay(date);
        const _today = startOfDay(new Date());
        return compareAsc(_date, _today);
    }
} as const;
