import { Dispatch } from 'redux';
import { OrdersSummary } from '../../entity';
import { OrdersActions } from './Orders.actions';

export const setNewOrdersCount = (count: number) => (dispatch: Dispatch) => {
    dispatch(OrdersActions.setNewOrdersCount(count));
}

export const setOrdersSummary = (summary: OrdersSummary) => (dispatch: Dispatch) => {
    dispatch(OrdersActions.setOrdersSummary(summary));
}
