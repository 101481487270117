import { FC } from 'react';
import { Loading } from '../Loading';

import styles from './AppLoading.module.scss';

export const AppLoading: FC = () => {
    return (
        <div className={styles['container']}>
            <Loading />
        </div>
    )
}
