const play = (file: string) => {
    const audio = new Audio(`/assets/mp3/${file}.mp3`);
    audio.play()
        .then(() => {

        })
        .catch(err => {
            console.warn('Error playing audio:');
            console.warn(err);
        });
}

export const Sounds = {
    Bell: () => play('bell'),
    InterfaceStart: () => play('interface-start'),
};
