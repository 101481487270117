import { FC, useRef, useEffect } from 'react';

import styles from './Loading.module.scss';

interface LoadingProps {
    size?: number;
}

export const Loading: FC<LoadingProps> = ({ size }) => {
    const refOne = useRef<HTMLDivElement>(null);
    const refTwo = useRef<HTMLDivElement>(null);
    const refThree = useRef<HTMLDivElement>(null);
    
    useEffect(() => {
        if(size) {
            if(refOne.current) {
                refOne.current.style.width = `${size}px`;
                refOne.current.style.height = `${size}px`;
            }

            if(refTwo.current) {
                refTwo.current.style.width = `${size}px`;
                refTwo.current.style.height = `${size}px`;
            }

            if(refThree.current) {
                refThree.current.style.width = `${size}px`;
                refThree.current.style.height = `${size}px`;
            }
        }
    }, [size]);

    return (
        <div className={styles['container']}>
            <div className={styles['one']} ref={refOne}></div>
            <div className={styles['two']} ref={refTwo}></div>
            <div className={styles['three']} ref={refThree}></div>
        </div>
    )
}
