export class Coupon {
    couponId: string;
    label: string;
    description: string;
    discount: number;
    expiresAt: Date;

    createdBy: string;
    createdAt: Date;
    updatedBy: string | null;
    updatedAt: Date | null;

    constructor(init?: Partial<Coupon>) {
        Object.assign(this, init);
    }

    public static Make(obj: any): Coupon {
        return new Coupon({
            couponId: obj.couponId,
            label: obj.label,
            description: obj.description,
            discount: obj.discount,
            expiresAt: new Date(obj.expiresAt),
            createdBy: obj.createdBy,
            createdAt: new Date(obj.createdAt),
            updatedBy: obj.updatedBy,
            updatedAt: obj.updatedAt ? new Date(obj.updatedAt) : null,
        });
    }
}
