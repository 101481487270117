import { Order, OrderContact, OrderContactType } from './Order';

export enum TemplateTags {
    Name = '[[NAME]]',
    PickupAddress = '[[PICKUP_ADDRESS]]',
    DeliveryAddress = '[[DELIVERY_ADDRESS]]',
}

export class Template {
    templateId: string;

    name: string;
    content: string;

    createdAt: Date;
    createdBy: string;
    updatedAt: Date | null;
    updatedBy: Date | null;

    constructor(init?: Partial<Template>) {
        Object.assign(this, init);
    }

    public static Make(obj: any): Template {
        const template = new Template();
        Object.assign(template, obj);

        template.createdAt = new Date(template.createdAt);
        template.updatedAt = template.updatedAt ? new Date(template.updatedAt) : null;

        return template;
    }

    public ReplaceTags(order: Order, orderContactType: OrderContactType | null): string {
        let content = this.content;

        content = content.replaceAll(TemplateTags.PickupAddress, `${order.pickup.venue.address} - ${order.pickup.venue.city}, ${order.pickup.venue.state} - ${order.pickup.venue.zip}`);
        content = content.replaceAll(TemplateTags.DeliveryAddress, `${order.delivery.venue.address} - ${order.delivery.venue.city}, ${order.delivery.venue.state} - ${order.delivery.venue.zip}`);

        const contact = order.Contact(orderContactType);
        if(contact) {
            content = content
                .replaceAll(TemplateTags.Name, contact.name);
        }

        return content;
    }
}
