import { FC } from 'react';

import styles from './Icon.module.scss';

export interface IconProps {
    icon: JSX.Element;
    customSize?: boolean;
    thin?: boolean;
    thick?: boolean;
    inline?: boolean;
    onClick?: () => void;
}

export const Icon: FC<IconProps> = ({ icon, customSize = false, thin = false, thick = false, inline = false, onClick }) => {
    const containerClass = `${styles['container']} ${customSize ? styles['custom-size'] : ''} ${thin ? styles['thin'] : ''} ${thick ? styles['thick'] : ''} ${inline ? styles['inline'] : ''} ${onClick ? styles['clickable'] : ''}`;
    return (
        <div className={containerClass} onClick={() => onClick?.()}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                preserveAspectRatio="xMidYMid meet"
                viewBox={icon.props.viewBox}
            >
                {icon}
            </svg>
        </div>
    );
}
