import { FC } from 'react';
import zxcvbn from 'zxcvbn';
import { Icon } from '..';
import { IconDot, IconCheck } from '../../shared';

import styles from './PasswordMeter.module.scss';

export enum PasswordMeterStrengths {
    VeryWeak = 0,
    Weak = 1,
    Medium = 2,
    Strong = 3,
    VeryStrong = 4,
};

const getScoreAttributes = (score: number | null) => {
    switch(score) {
        case 0:
            return ['very-weak', 'Very weak'];
        
        case 1:
            return ['weak', 'Weak'];
        
        case 2:
            return ['medium', 'Average'];
        
        case 3:
            return ['strong', 'Strong'];
        
        case 4:
            return ['very-strong', 'Very strong'];
        
        default:
            return ['empty', ''];
    }
}

const hasSymbol = (password: string) => password.match(/[!@#$%^&*()_+=[{\]};:<>|./?,-]/);
const hasNumber = (password: string) => password.match(/[0-9]+/);
const hasUpper = (password: string) => password.match(/[A-Z]+/);
const hasLength = (password: string) => password.match(/.{8,60}/);

interface PasswordMeterProps {
    password: string;
    strengthCallback?: (score: number | null) => void;
    showRequirements?: boolean;
}

export const PasswordMeter: FC<PasswordMeterProps> = ({ password, strengthCallback, showRequirements = false }) => {
    const score = password?.length ? zxcvbn(password).score : null;

    const [_class, text] = getScoreAttributes(score);

    let list: Array<any> = [];
    if(showRequirements) {
        list = [
            {
                text: '8 characters',
                fulfilled: hasLength(password)
            },
            {
                text: 'One uppercase letter',
                fulfilled: hasUpper(password)
            },
            {
                text: 'One number',
                fulfilled: hasNumber(password)
            },
            {
                text: 'One special character',
                fulfilled: hasSymbol(password)
            }
        ];
    }

    strengthCallback?.(score);

    return (
        <div className={styles['container']}>
            <div className={`${styles['strength']} ${styles[_class]}`}>
                <div className={styles['title']}>Password strength:</div>
                <div className={styles['text']}>{text}</div>
                <div className={styles['progress']}>
                    {[ ...Array(5) ].map((_, i) => <div key={i} className={styles['progress-item']}></div>)}
                </div>
            </div>
            {showRequirements &&
                <div className={styles['requirements']}>
                    <div className={styles['title']}>Your password should have at least:</div>
                    <div className={styles['list']}>
                        {list.map(({ text, fulfilled }, i) => {
                            const icon = fulfilled ? IconCheck : IconDot;
                            return (
                                <div className={`${styles['list-item']} ${fulfilled ? styles['fulfilled'] : ''}`} key={i}>
                                    <div className={styles['icon']}><Icon icon={icon} customSize /></div>
                                    <div className={styles['text']}>{text}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            }
        </div>
    )
}
