import { IconNavigation, IconTruck, IconUser } from '../shared';

export enum UserRoles {
    Admin = 'admin',
    Driver = 'driver',
    Client = 'client',
}

export namespace UserRoles {
    export const Info = (role: UserRoles): { label: string, icon: JSX.Element } => {
        switch(role) {
            case UserRoles.Admin:
                return { label: 'Admin', icon: IconNavigation };
            case UserRoles.Driver:
                return { label: 'Driver', icon: IconTruck };
            case UserRoles.Client:
                return { label: 'Client', icon: IconUser };
        }
    }
}

export enum UserStatus {
    Active = 'active',
    Inactive = 'inactive',
}

export namespace UserStatus {
    export const Info = (status: UserStatus): { label: string, color: string } => {
        switch(status) {
            case UserStatus.Active:
                return { label: 'Active', color: 'green' };
            case UserStatus.Inactive:
                return { label: 'Deactivated', color: 'grey' };
        }
    }
}

export class User {
    userId: string;
    role: UserRoles;
    name: string;
    email: string;
    phone: string;
    status: UserStatus;

    constructor(init?: Partial<User>) {
        Object.assign(this, init);
    }

    public static Make(obj: any): User {
        const user = new User();
        Object.assign(user, obj);
        return user;
    }
}
