import { config } from '../config';
import { HttpMethods } from '../shared/constants';
import { ApiClient, ApiRequest, ApiResponse } from './ApiClient';

export const DriversService = {
    ListDrivers: async (): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.GET,
            resource: '/drivers',
            auth: config.Auth.Token(),
        });

        return ApiClient.ExecuteRequest(request);
    }
}
