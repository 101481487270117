export class Driver {
    driverId: string;
    name: string;
    email: string;
    phone: string;
    truckCapacity: number;

    constructor(init?: Partial<Driver>) {
        Object.assign(this, init);
    }

    public static Make(obj: any): Driver {
        const driver = new Driver();
        Object.assign(driver, obj);
        return driver;
    }
}
