import { FC } from 'react';
import ReactSkeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import styles from './Skeleton.module.scss';

interface SkeletonProps {
    grid?: boolean;
    list?: boolean;
    details?: boolean;
    box?: boolean;
    className?: string;
}

export const Skeleton: FC<SkeletonProps> = ({ grid = false, list = false, details = false, box = false, className = '' }) => {
    if(details) {
        return (
            <div className={`${className} ${styles['details']}`}>
                <div className={styles['details-cover']}>
                    <ReactSkeleton height={400} />
                </div>
                <div className={styles['details-info']}>
                    <div><ReactSkeleton height={40} width={200} /></div>
                    <div><ReactSkeleton height={70} width={300} /></div>

                    <div><ReactSkeleton height={30} width={170} /></div>
                    <div><ReactSkeleton height={30} width={170} /></div>

                    <div><ReactSkeleton height={40} width={200} /></div>
                </div>
            </div>
        )
    }

    if(box) {
        return (
            <div className={`${className} ${styles['box']}`}>
                <ReactSkeleton height={300} />
            </div>
        )
    }

    if(grid) {
        return (
            <div className={`${className} ${styles['grid']}`}>
                {[ ...Array(8) ].map((_, i) => (
                    <div className={styles['grid-item']} key={i}>
                        <ReactSkeleton height={200} />
                    </div>
                ))}
            </div>
        )
    }

    if(list) {
        return (
            <div className={`${className} ${styles['list']}`}>
                {[ ...Array(7) ].map((_, i) => (
                    <div className={styles['list-item']} key={i}>
                        <ReactSkeleton height={40} />
                    </div>
                ))}
            </div>
        )
    }

    return (
        <div className={`${className} ${styles['admin']}`}>
            <div className={styles['top']}>
                <div className={styles['button']}>
                    <ReactSkeleton height={40} />
                </div>
            </div>

            <div className={styles['admin-list']}>
                {[ ...Array(5) ].map((_, i) => (
                    <div className={styles['admin-list-item']} key={i}>
                        <ReactSkeleton height={30} />
                    </div>
                ))}
            </div>
        </div>
    )
}
