import { FC } from 'react';
import { Icon, Loading, Button } from '..';
import { IconX } from '../../shared';

import styles from './ModalDialog.module.scss';

export enum ModalDialogThemes {
    Green = 'green',
    Yellow = 'yellow',
    Red = 'red',
    Blue = 'blue',
}

export interface ModalDialogProps {
    icon?: JSX.Element;
    theme?: ModalDialogThemes;
    title?: string | JSX.Element;
    text?: string | JSX.Element;
    details?: string | Array<string>;
    buttonMainValue?: string;
    buttonMainAction?: () => void;
    buttonMainLink?: string;
    buttonSecValue?: string;
    buttonSecAction?: () => void;
    buttonSecLink?: string;
    showCloseButton?: boolean;
    closeButtonAction?: () => void;
    alignLeft?: boolean;
    isLoading?: boolean;
    buttonCentered?: boolean;
    className?: string;
}

export const ModalDialog: FC<ModalDialogProps> = ({
    icon,
    theme = ModalDialogThemes.Green,
    title,
    text,
    children,
    details,
    buttonMainValue,
    buttonMainAction,
    buttonMainLink,
    buttonSecValue,
    buttonSecAction,
    buttonSecLink,
    showCloseButton = false,
    closeButtonAction,
    alignLeft,
    isLoading = false,
    buttonCentered = false,
    className = ''
}) => {
    const content = children ? children : text ? text : null;
    const classContainer = `${styles['container']} ${theme ? styles[`theme-${theme}`] : ''} ${alignLeft ? styles['align-left'] : ''} ${buttonCentered ? styles['button-centered'] : ''} ${isLoading ? styles['is-loading'] : ''} ${className}`;

    const handleCloseClick = () => {
        if(isLoading)
            return;
        
        closeButtonAction?.();
    }

    return (
        <div className={classContainer}>

            <div className={styles['top']}>
                {showCloseButton &&
                    <div className={styles['close']} onClick={handleCloseClick}>
                        <Icon icon={IconX} />
                    </div>
                }
            </div>

            <div className={styles['content']}>

                {icon &&
                    <div className={styles['icon']}>
                        <div className={styles['icon-holder']}><Icon icon={icon} customSize /></div>
                    </div>
                }

                {title &&
                    <div className={styles['title']}>
                        {title}
                    </div>
                }

                {content && 
                    <div className={styles['text']}>
                        {content}
                    </div>
                }

                {details &&
                    <div className={styles['details']}>
                        {typeof details === 'string' ? details : details.map((d, i) => <div key={i} className={styles['details-item']}>{d}</div>)}
                    </div>
                }

                {(buttonMainValue || buttonSecValue) &&
                    <div className={styles['buttons']}>
                        <div className={styles['buttons-holder']}>
                        {buttonSecValue &&
                            <div className={styles['buttons-item']}>
                                <Button
                                    label={buttonSecValue}
                                    onClick={() => buttonSecAction?.()}
                                    theme={'negative'}
                                    link={buttonSecLink}
                                />
                            </div>
                        }
                        {buttonMainValue &&
                            <div className={styles['buttons-item']}>
                                <Button
                                    label={buttonMainValue}
                                    onClick={() => buttonMainAction?.()}
                                    link={buttonMainLink}
                                />
                            </div>
                        }
                        {isLoading &&
                            <div className={styles['loading']}>
                                <Loading />
                            </div>
                        }
                        </div>
                    </div>
                }

            </div>

        </div>
    )
}
