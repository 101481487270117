import { format } from 'date-fns';

export const Util = {
    ScrollTo: (height: number, smooth: boolean = true): void => {
        window.scroll({ top: height, behavior: smooth ? 'smooth' : 'auto' });
    },

    IsEmpty: (str: string): boolean => {
        if(str === null || str === undefined || str.length === 0)
            return true;
        return str.replace(/\s/g, '').length < 1;
    },

    IsNumberEmpty: (number: any): boolean => {
        return number === null || number === undefined || isNaN(number);
    },

    IsNumeric: (str: string, ignore?: Array<string> | null, mask?: string | null): boolean => {
        if(Util.IsEmpty(str))
            return false;
        
        const _ignore = ignore ? ignore.join('') : [];
        const _mask = mask ? [ ...new Set(mask.replace(/x/gi, '')) ].join().replace('/', '\\/').replace('-', '\\-') : '';

        const regex = new RegExp(`^[0-9${_ignore}${_mask}]+`);
        return str.replace(regex, '').length === 0;
    },

    IsAlpha: (str: string, ignore: Array<string> = []): boolean => {
        if(Util.IsEmpty(str))
            return false;
        
        let _ignore: string | Array<string> = [' ', 'á', 'é', 'í', 'ó', 'ú', 'Á', 'É', 'Í', 'Ó', 'Ú', 'ç', 'Ç', 'ã', 'õ', 'Ã', 'Õ', '´', '˜'];
        _ignore = [ ...ignore, ..._ignore ];
        _ignore = _ignore.join('').replace(/[.*+?^${}()\-|[\]\\]/g, '\\$&');

        const regex = new RegExp(`[^a-zA-Z${_ignore}]`, 'gi');
        const _str = str.replace(regex, '');
        return str.length === _str.length;
    },

    Mask: (mask: string | null, value: string) => {
        if(!mask)
            return value;
        
        if(!value?.length)
            return '';

        let _value = value.replace(/[^a-zA-Z0-9]/gi, '').split('');
        let _mask = mask.split('');
        for(let i = 0; ; i++) {
            if(i > mask.length)
                break;

            if(_mask[i] !== 'x') {
                continue;
            }
            else{
                _mask[i] = _value[0];
                _value.splice(0, 1);

                if(_value.length === 0) {
                    const increment = _mask[i + 1] !== 'x' && value.length === i + 2 ? i + 2 : i + 1;
                    _mask.splice(increment, _mask.length);
                    break;
                }
            }
        }
        return _mask.join('');
    },

    Masks: (masks: string[], value: string): string => {
        if(!masks?.length)
            return value;
        
        if(!value?.length)
            return '';
        
        let i = 0;
        while(true) {
            const mask = masks[i];
            
            if(value.length <= mask.length)
                return Util.Mask(mask, value);
            
            if(i < masks.length - 1)
                i++;
            else
                return Util.Mask(mask, value);
        }
    },

    IsEmailValid: (email: string): boolean => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    },

    Sleep: (seconds: number): Promise<void> => {
        return new Promise<void>(resolve => setTimeout(resolve, seconds * 1000));
    },

    FormatDateAbbr: (date: Date): string => {
        return format(date, 'LLL dd, yyyy');
    },

    FormatDateAbbrTime: (date: Date): string => {
        return format(date, 'LLL dd, yyyy - hh:mm a');
    },

    RemoveHtmlTags: (str: string): string => {
        return str.replaceAll(/(<([^>]+)>)/ig, ' ');
    },

    CutString: (str: string, size: number, suffix: string = '...'): string => {
        const newStr = [...str].slice(0, size).join('');
        return `${newStr}${newStr.length < [...str].length ? suffix : ''}`;
    },

    Slugify: (value: string): string => {
        const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
        const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
        const p = new RegExp(a.split('').join('|'), 'g')

        return value.toString().toLowerCase()
            .replace(/\s+/g, '-') // Replace spaces with -
            .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
            .replace(/&/g, '-and-') // Replace & with 'and'
            .replace(/[^\w-]+/g, '') // Remove all non-word characters
            .replace(/--+/g, '-') // Replace multiple - with single -
            .replace(/^-+/, '') // Trim - from start of text
            .replace(/-+$/, '') // Trim - from end of text
    },

    BytesToSize(bytes: number): string {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0)
            return '0 Byte';
        
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
    },

    FindLastIndex<T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number {
        let l = array.length;
        while (l--) {
            if (predicate(array[l], l, array))
                return l;
        }
        return -1;
    },

    Round: (value: number): number => {
        return Math.round((value + Number.EPSILON) * 100) / 100;
    },

    FormatCurrency: (value: number | null, sign: string | null = '$'): string => {
        if (!value || isNaN(value))
            return '';
        
        const rounded = Util.Round(value);
        if (rounded >= 0)
            return `${sign ? `${sign} ` : ''}${rounded.toLocaleString('en-US', { minimumFractionDigits: 2 })}`;
        return `- ${sign ? `${sign} ` : ''}${(rounded * -1).toLocaleString('en-US', { minimumFractionDigits: 2 })}`;
    },

    MetersToMiles: (meters: number): number => {
        return Util.Round(meters / 1609);  
    },

    FormatSecondsInHours: (seconds: number): string => {
        const [hours, fraction] = Util.Round(seconds / 3600).toString().split('.');
        const minutes = Math.ceil(Util.Round((59 * Number(fraction)) / 99));
        return `${Math.floor(Number(hours))}h${String(minutes).padStart(2, '0')}`;
    },

    CopyToClipboard: async (text: string): Promise<boolean> => {
        if(!('clipboard' in navigator))
            return false;
        
        await navigator.clipboard.writeText(text);
        return true;
    },
} as const;
