import { FC } from 'react';
import { lazily } from 'react-lazily';
import { Route, Routes } from 'react-router-dom';
import { SuspenseHandler } from '../handlers';

import styles from './App.module.scss';

const { Dashboard } = lazily(() => import('../dashboard'));

export const App: FC = () => {
    return (
        <div className={styles['container']}>
            <Routes>
                <Route
                    path='*'
                    element={
                        <SuspenseHandler>
                            <Dashboard />
                        </SuspenseHandler>
                    }
                />
            </Routes>
        </div>
    )
}
