import { Coordinates } from './Coordinates';
import { Location } from './Location';
import { Route } from './Route';
import { Vehicle } from './Vehicle';

export enum QuoteType {
    Automatic = 'automatic',
    Manual = 'manual',
};

export enum QuoteStatus {
    Created = 'created',
    Analysis = 'analysis',
    Priced = 'priced',
    Completed = 'completed',
    Paid = 'paid',
    PaymentError = 'payment-error',
};

export enum QuotePosition {
    Active = 'active',
    Archived = 'archived',
};

export class Quote {
    quoteId: string;
    quoteNumber: number;
    quoteToken: string;
    quoteType: QuoteType;
    quoteStatus: QuoteStatus;

    pickup: Location;
    pickupDate: Date;
    delivery: Location;

    vehicle: Vehicle;

    email: string;
    phone: string;
    name: string;

    couponLabel: string;
    couponId: string;
    couponDiscount: number | null;

    price: number | null;
    total: number | null;
    route: Route;

    createdAt: Date;
    pricedAt: Date;
    accessedAt: Date | null;

    position: QuotePosition;

    constructor(init?: Partial<Quote>) {
        this.pickup = new Location();
        this.delivery = new Location();
        this.vehicle = new Vehicle();
        Object.assign(this, init);
    }

    public static Make(obj: any): Quote {
        return new Quote({
            quoteId: obj.quoteId,
            quoteNumber: obj.quoteNumber,
            quoteToken: obj.quoteToken,
            quoteType: obj.quoteType,
            quoteStatus: obj.quoteStatus,

            pickup: new Location({
                locationSid: obj.pickup.locationSid,
                city: obj.pickup.city,
                state: obj.pickup.state,
                country: obj.pickup.country,
                coordinates: new Coordinates({
                    lat: obj.pickup.coordinates.lat,
                    long: obj.pickup.coordinates.long,
                }),
                address: obj.pickup.address,
            }),
            pickupDate: new Date(obj.pickupDate),

            delivery: new Location({
                locationSid: obj.delivery.locationSid,
                city: obj.delivery.city,
                state: obj.delivery.state,
                country: obj.delivery.country,
                coordinates: new Coordinates({
                    lat: obj.delivery.coordinates.lat,
                    long: obj.delivery.coordinates.long,
                }),
                address: obj.delivery.address,
            }),

            vehicle: new Vehicle({
                size: obj.vehicle.size,
                year: obj.vehicle.year,
                make: obj.vehicle.make,
                model: obj.vehicle.model,
                running: typeof obj.vehicle.running === 'boolean' ? obj.vehicle.running : null,
            }),

            email: obj.email,
            phone: obj.phone,
            name: obj.name,

            couponLabel: obj.couponLabel,
            couponId: obj.couponId,
            couponDiscount: obj.couponDiscount,

            price: obj.price,
            total: obj.total,
            route: new Route({
                distance: obj.route.distance,
                duration: obj.route.duration,
            }),

            createdAt: new Date(obj.createdAt),
            pricedAt: new Date(obj.pricedAt),
            accessedAt: obj.accessedAt ? new Date(obj.accessedAt) : null,

            position: obj.position,
        });
    }
}
