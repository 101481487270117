import axios, { AxiosError } from 'axios';
import { config } from '../config';
import { HttpStatus } from '../shared/constants';

const client = axios.create({
    baseURL: config.Uploads.Api,
    headers: {
        'Content-type': 'application/json'
    },
    withCredentials: true,
});

interface UploadResponse {
    filenames: Array<string>;
    message: string;
    statusCode: HttpStatus;
    failure: boolean;
}

export const UploadService = {
    Upload: async (file: Blob, onUploadProgress: (ev: ProgressEvent) => void, resize?: number, thumb?: number): Promise<UploadResponse> => {
        let response: UploadResponse;

        try {
            const formData = new FormData();
            formData.append('file[]', file);
            formData.append('serviceKey', config.Uploads.ServiceKey);
            formData.append('resize', String(resize));
            formData.append('thumb', String(thumb));

            const result = await client.post('/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress,
            });

            if(!result.data.filenames?.length)
                throw new Error();

            response = {
                filenames: result.data.filenames,
                statusCode: result.status,
                failure: false,
                message: '',
            };
        }
        catch(err: any) {
            response = {
                filenames: [],
                message: 'Unexpected error',
                statusCode: 500,
                failure: true,
            };

            if(axios.isAxiosError(err)) {
                response.message = (err as AxiosError).response?.data?.message ?? 'Unexpected error';
                response.statusCode = (err as AxiosError).response?.status ?? 500;
            }
        }

        return response;
    }
}
