import { config } from '../config';
import { TripRoute, TripStatus } from '../entity';
import { HttpMethods } from '../shared/constants';
import { ApiClient, ApiRequest, ApiResponse } from './ApiClient';

interface CreateTripParams {
    name: string;
    driverId: string;
}

interface EditTripParams {
    name: string;
    driverId: string;
    ordersIds: string[];
    route: TripRoute[];
}

export const TripsService = {
    CreateTrip: async (params: CreateTripParams): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.POST,
            resource: '/trips',
            auth: config.Auth.Token(),
            body: { ...params },
        });

        return await ApiClient.ExecuteRequest(request);
    },

    GetTrip: async (tripId: string): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.GET,
            resource: `/trips/${tripId}`,
            auth: config.Auth.Token(),
        });

        return await ApiClient.ExecuteRequest(request);
    },

    EditTrip: async (tripId: string, params: EditTripParams): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.PUT,
            resource: `/trips/${tripId}`,
            auth: config.Auth.Token(),
            body: { ...params },
        });

        return await ApiClient.ExecuteRequest(request);
    },

    ListTrips: async (status: TripStatus): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.GET,
            resource: `/trips?status=${status}`,
            auth: config.Auth.Token(),
        });

        return await ApiClient.ExecuteRequest(request);
    },

    EditTripStatus: async (tripId: string, status: TripStatus): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.PATCH,
            resource: `/trips/${tripId}/status`,
            auth: config.Auth.Token(),
            body: { status },
        });

        return await ApiClient.ExecuteRequest(request);
    },
}
