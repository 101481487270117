import { OrdersSummary } from '../../entity';

const prefix = `@ORDERS`;

export const OrdersActionsConstants = {
    SetNewOrdersCount: `${prefix}_SET_NEW_ORDERS_COUNT`,
    SetOrdersSummary: `${prefix}_SET_ORDERS_SUMMARY`,
};

export const OrdersActions = {
    setNewOrdersCount: (count: number): ReduxAction => ({
        type: OrdersActionsConstants.SetNewOrdersCount,
        payload: count,
    }),

    setOrdersSummary: (summary: OrdersSummary): ReduxAction => ({
        type: OrdersActionsConstants.SetOrdersSummary,
        payload: summary,
    }),
};
