import { UserRoles } from '.';

export class Session {
    token: string;
    expiresAt: Date;
    role: UserRoles;

    constructor(init?: Partial<Session>) {
        Object.assign(this, init);
    }

    static MakeSession(obj: any): Session {
        return new Session({
            token: obj.token,
            expiresAt: new Date(obj.expiresAt),
            role: obj.role,
        });
    }
}
