import { FC, useEffect, useState } from 'react';
import { Connect, ConnectProps, RootState } from '../infrastructure'
import { resetMessagesUnreadCount, setMessagesUnreadCount } from '../redux/messages/Messages.operations';
import { MessagesService } from '../services';
import { useInterval } from '../shared/hooks';

interface MessagesUnreadHandlerProps extends ReduxProps {}
const MessagesUnreadHandler: FC<MessagesUnreadHandlerProps> = ({ isAuth, currentCount, setUnreadCount, resetUnreadCount, resetMessagesUnreadCount }) => {
    const [delay, setDelay] = useState<number | null>(null);

    useEffect(() => {
        if(isAuth) {
            getUnreadCount();
            setDelay(30_000);
        }
        else {
            setDelay(null);
        }
    }, [isAuth]);

    useEffect(() => {
        if(resetUnreadCount) {
            getUnreadCount();
            resetMessagesUnreadCount(false);
        }
    }, [resetUnreadCount]);

    useInterval(() => getUnreadCount(), delay);

    const getUnreadCount = () => {
        MessagesService.GetUnreadCount()
            .then(response => {
                if(response.failure)
                    return;
                const { count } = response.body.data;
                if(currentCount !== count)
                    setUnreadCount(count);
            })
    }

    return null;
}

const mapState = ({ auth, messages }: RootState) => ({
    isAuth: !!auth.session,
    currentCount: messages.unreadCount,
    resetUnreadCount: messages.resetUnreadCount,
});

const mapDispatch = {
    setUnreadCount: (count: number) => setMessagesUnreadCount(count),
    resetMessagesUnreadCount: (reset: boolean) => resetMessagesUnreadCount(reset),
};

const connector = Connect(mapState, mapDispatch);
type ReduxProps = ConnectProps<typeof connector>;
const MessagesUnreadHandlerConnected = connector(MessagesUnreadHandler);
export { MessagesUnreadHandlerConnected as MessagesUnreadHandler };
