import { FC, useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { ToastProps } from '../components';
import { config } from '../config';
import { OrdersSummary, OrderStatus } from '../entity';
import { OrdersUpdated } from '../entity/SocketEvents';
import { Connect, ConnectProps, RootState } from '../infrastructure';
import { setNewOrdersCount, setOrdersSummary } from '../redux/orders/Orders.operations';
import { IconCornerRightDown, IconCornerRightUp, IconStar, Routes } from '../shared';
import { Sounds } from '../shared/constants';
import { useToast } from '../shared/hooks';

const SocketHandler: FC<ReduxProps> = ({ isAuth, setNewOrdersCount, setOrdersSummary }) => {
    const [socket, setSocket] = useState<Socket>();
    const { setToast } = useToast();

    useEffect(() => {
        const socket = io(config.Api.Base, {
            path: `${config.Api.Path}/ws`
        });
        socket.on('connect', () => {
            console.log(`[${new Date().toISOString()}] connected`);
            setSocket(socket);
        });
        socket.on('disconnect', reason => {
            console.log(`[${new Date().toISOString()}] disconnected - reason: ${reason}`);
            if(reason === 'io server disconnect') {
                socket.connect();
            }
        });

        socket.on('orders.update', handleOrdersSummaryUpdate);
    }, []);

    useEffect(() => {
        if(!isAuth) {
            socket?.close();
            setNewOrdersCount(0);
        }
    }, [isAuth]);

    const handleOrdersSummaryUpdate = ({ summary, status, orderId, number }: OrdersUpdated) => {
        setOrdersSummary(summary);

        if(status === OrderStatus.New) {
            Sounds.Bell();
            setToast({
                icon: IconStar,
                text: `Load ${number} has been added`,
                theme: 'indigo',
                link: `${Routes.Dashboard.Orders.Details}/${orderId}`,
            });
        }
    }

    return null;
}

const mapState = ({ auth }: RootState) => ({
    isAuth: !!auth.session,
});

const mapDispatch = {
    setNewOrdersCount: (count: number) => setNewOrdersCount(count),
    setOrdersSummary: (summary: OrdersSummary) => setOrdersSummary(summary),
};

const connector = Connect(mapState, mapDispatch);
type ReduxProps = ConnectProps<typeof connector>;
const SocketHandlerConnected = connector(SocketHandler);
export { SocketHandlerConnected as SocketHandler };
