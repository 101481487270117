import { forwardRef, ReactElement } from 'react';
import { Icon, Loading } from '..';
import Ink from 'react-ink';
import { Link } from 'react-router-dom';

import styles from './Button.module.scss';

interface ButtonProps {
    label?: string;
    onClick?: Function;
    isLoading?: boolean;
    icon?: ReactElement;
    iconAfter?: boolean;
    collapse?: boolean;
    theme?: string;
    red?: boolean;
    main?: boolean;
    disabled?: boolean;
    popover?: JSX.Element | null;
    popOnHover?: JSX.Element;
    link?: string;
    compact?: boolean;
}

const Button = forwardRef<HTMLDivElement, ButtonProps>(({ label, onClick, isLoading, icon, iconAfter, collapse, theme = null, red, disabled, popover, popOnHover, link, main, compact }, ref) => {
    const content = (
        <>
            {icon && <div className={styles['icon']}><Icon icon={icon} customSize /></div>}
            {label && <div className={styles['label']}>{label}</div>}
            {isLoading && <div className={styles['loading']}><Loading /></div>}
            {popover && <div className={styles['popover']}>{popover}</div>}
            {popOnHover && <div className={styles['pop-on-hover']}>{popOnHover}</div>}
            <Ink />
        </>
    );

    const containerClass = `${styles['container']} ${isLoading ? styles['is-loading'] : ''} ${collapse ? styles['collapse'] : ''} ${theme ? styles[`theme-${theme}`] : ''} ${red ? styles['red'] : ''} ${disabled ? styles['disabled'] : ''} ${iconAfter ? styles['icon-after'] : ''} ${main ? styles['main'] : ''} ${compact ? styles['compact'] : ''}`;

    if(link) {
        if(link.includes('http')) {
            return (
                <div className={containerClass} onClick={ev => { ev.stopPropagation(); !isLoading && !disabled && onClick?.(); }} ref={ref}>
                    <a href={link} target='_blank' rel='noopener noreferrer'>
                        <div className={styles['holder']}>
                            {content}
                        </div>
                    </a>
                </div>
            )
        }

        return (
            <div className={containerClass} onClick={ev => { ev.stopPropagation(); !isLoading && !disabled && onClick?.(); }} ref={ref}>
                <Link to={link}>
                    <div className={styles['holder']}>
                        {content}
                    </div>
                </Link>
            </div>
        )
    }

    return (
        <div className={containerClass} onClick={ev => { ev.stopPropagation(); !isLoading && !disabled && onClick?.(); }} ref={ref}>
            <div className={styles['holder']}>
                {content}
            </div>
        </div>
    )
});

Button.displayName = 'Button';

export { Button };
