import { FC, useState, MouseEvent } from 'react';
import { Icon } from '..';
import { IconX, Util } from '../../shared';
import { useRedirect, useTimeout } from '../../shared/hooks';

import styles from './Toast.module.scss';

const ToastThemes = {
    red: 'red',
    yellow: 'yellow',
    green: 'green',
    blue: 'blue',
    indigo: 'indigo',
}

export interface ToastProps {
    text?: string;
    details?: Array<string>;
    icon?: JSX.Element;
    time?: number;
    onClose?: (id: string) => void;
    onClick?: () => void;
    theme?: keyof typeof ToastThemes;
    id?: string;
    persist?: boolean;
    link?: string;
}

export const Toast: FC<ToastProps> = props => {
    const {
        text,
        details,
        icon,
        onClose,
        onClick,
        theme,
        id,
        persist = false,
        link,
    } = props;

    const [isClosing, setIsClosing] = useState<boolean>(false);
    const redirect = useRedirect();

    useTimeout(() => {
        handleClose();
    }, persist ? null : 5 * 1000);

    const handleClose = async (ev?: MouseEvent) => {
        ev?.stopPropagation();

        setIsClosing(true);
        await Util.Sleep(.3);
        onClose?.(id as string);
    }

    const handleOnClick = () => {
        if(link)
            redirect(link);
        onClick?.();
        handleClose();
    }

    const containerClass = `${styles['container']} ${theme ? styles[`theme-${String(theme)}`] : ''} ${isClosing ? styles['is-closing'] : ''} ${onClick || link ? styles['clickable'] : ''}`;
    return (
        <div className={containerClass} onClick={handleOnClick}>
            <div className={styles['content']}>
                {icon && <div className={styles['icon']}><Icon icon={icon} customSize /></div>}
                <div className={styles['main']}>
                    <div className={styles['text']}>{text}</div>
                    {details && <div className={styles['details']}>{details.map((detail, i) => <div key={i}>{detail}</div>)}</div>}
                </div>
                <div className={styles['close']} onClick={ev => handleClose(ev)}>
                    <Icon icon={IconX} customSize />
                </div>
            </div>
        </div>
    )
}
