import { useState, useRef, ChangeEvent, KeyboardEvent, MouseEvent, forwardRef, RefObject } from 'react';
import { Icon, Loading, PasswordMeter } from '..';
import { IconEye, IconEyeOff, Util } from '../../shared';
import { KeyCodes } from '../../shared/constants';

//@ts-ignore
import CurrencyInput from 'react-intl-currency-input';

import styles from './Input.module.scss';

export enum InputModes {
    Text = 'text',
    Decimal = 'decimal',
    Numeric = 'numeric',
    Tel = 'tel',
    Search = 'search',
    Email = 'email',
    Url = 'url',
    None = 'none',
}

interface InputProps {
    label?: string;
    placeholder?: string;
    value: string;
    onChange?: (value: string) => void;
    disabled?: boolean;
    onFocus?: () => void;
    onBlur?: () => void;
    theme?: string;
    error?: string;
    inputMode?: InputModes;
    onlyNumeric?: boolean;
    onlyNumericIgnore?: Array<string>;
    onlyAlpha?: boolean;
    onlyAlphaIgnore?: Array<string>;
    mask?: string;
    masks?: string[];
    onlyLowerCase?: boolean;
    onlyUpperCase?: boolean;
    noSpaces?: boolean;
    maxlength?: number;
    userChangeable?: boolean;
    icon?: JSX.Element;
    isLoading?: boolean;
    disableOnLoading?: boolean;
    password?: boolean;
    onEnter?: () => void;
    onEsc?: () => void;
    passwordMeter?: (strength: number | null) => void;
    passwordMeterRequirements?: boolean;
    prefix?: string;
    suffix?: string;
    readonly?: boolean;
    money?: boolean;
    moneyValue?: number;
    onChangeMoney?: (maskedValue: string, moneyValue: number) => void;
    textarea?: boolean;
    iconClick?: () => void;
}

const Input = forwardRef<HTMLInputElement | HTMLTextAreaElement | null, InputProps>(({
    label,
    placeholder,
    value,
    onChange,
    disabled,
    onFocus,
    onBlur,
    theme,
    error,
    inputMode = InputModes.Text,
    onlyNumeric = false,
    onlyNumericIgnore = [],
    onlyAlpha = false,
    onlyAlphaIgnore = [],
    mask,
    masks,
    onlyLowerCase = false,
    onlyUpperCase = false,
    noSpaces = false,
    maxlength = -1,
    userChangeable = true,
    icon,
    isLoading = false,
    disableOnLoading = false,
    password = false,
    onEnter,
    onEsc,
    passwordMeter = null,
    passwordMeterRequirements = false,
    prefix = null,
    suffix = null,
    readonly = false,
    money = false,
    moneyValue = null,
    onChangeMoney,
    textarea = false,
    iconClick,
}, ref) => {
    const [hasFocus, setHasFocus] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);

    const localRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
    const forwardedRef = ref as RefObject<HTMLInputElement | HTMLTextAreaElement> ?? null;

    const inputRef = forwardedRef ?? localRef;

    const handleOnChange = (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if(!userChangeable || (isLoading && disableOnLoading) || readonly)
            return;
        
        let { value } = ev.target;

        if(value.length && onlyNumeric && !Util.IsNumeric(value, onlyNumericIgnore, mask ?? masks?.join('')))
            return;
        if(value.length && onlyAlpha && !Util.IsAlpha(value, onlyAlphaIgnore))
            return;
        if(!!mask && value.length > mask.length)
            return;
        
        if(onlyUpperCase)
            value = value.toLocaleUpperCase();
        if(onlyLowerCase)
            value = value.toLocaleLowerCase();
        
        if(noSpaces)
            value = value.replace(/\s/gi, '');
        
        onChange && onChange(value);
    }

    const handleMoneyOnChange = (_: any, value: any, maskedValue: any) => {
        if(!userChangeable || (isLoading && disableOnLoading))
            return;
        
        onChangeMoney?.(maskedValue, value);
    }

    const handleFocus = () => {
        if(disabled || readonly) {
            // inputRef.current?.blur();
            return;
        }
        setHasFocus(true);
        onFocus && onFocus();
    }

    const handleBlur = () => {
        setHasFocus(false);
        onBlur && onBlur();
    }

    const handleKeyDown = ({ keyCode }: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if(keyCode === KeyCodes.Enter) {
            onEnter?.();
            return;
        }

        if(keyCode === KeyCodes.Esc) {
            onEsc?.();
            return;
        }
    }

    const handleIconClick = (ev: MouseEvent<HTMLDivElement>) => {
        if(password) {
            ev.stopPropagation();
            setPasswordVisible(!passwordVisible);
            // Util.setCaretPosition(ref);
        }

        iconClick?.();
    }

    if(password)
        icon = passwordVisible ? IconEyeOff : IconEye;

    const type = password && !passwordVisible ? 'password' : 'text';
    const containerClass = `
        ${styles['container']}
        ${hasFocus ? styles['has-focus'] : ''}
        ${(!!value?.length || !!moneyValue) ? styles['has-value'] : ''}
        ${theme ? styles[`theme-${theme}`] : ''}
        ${!!error ? styles['has-error'] : ''}
        ${disabled ? styles['disabled'] : ''}
        ${password ? styles['is-password'] : ''}
        ${readonly ? styles['readonly'] : ''}
        ${textarea ? styles['textarea'] : ''}
        ${!!!label ? styles['labelless'] : ''}
        ${iconClick ? styles['icon-clickable'] : ''}
    `;
    return (
        <div className={containerClass} onClick={() => inputRef.current?.focus()}>
            <div className={styles['content']}>
                <div className={styles['label']}>{label}</div>
                <div className={styles['content-holder']}>
                    {prefix && <div className={styles['prefix']}>{prefix}</div>}
                    {money ?
                        <CurrencyInput
                            className={styles['input']}
                            onChange={handleMoneyOnChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            value={moneyValue}
                        />
                        :
                        textarea ?
                            <textarea
                                ref={inputRef as RefObject<HTMLTextAreaElement>}
                                className={styles['input']}
                                value={value}
                                onChange={ev => handleOnChange(ev)}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                placeholder={placeholder}
                                onKeyDown={ev => handleKeyDown(ev)}
                            />
                            :
                            <input
                                ref={inputRef as RefObject<HTMLInputElement>}
                                className={styles['input']}
                                type={type}
                                value={mask ? Util.Mask(mask, value) : masks ? Util.Masks(masks, value) : value}
                                onChange={ev => handleOnChange(ev)}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                placeholder={placeholder}
                                inputMode={inputMode}
                                maxLength={maxlength}
                                onKeyDown={ev => handleKeyDown(ev)}
                            />
                    }
                    {suffix && <div className={styles['suffix']}>{suffix}</div>}
                </div>
                {(icon || isLoading) &&
                    <>
                        {isLoading &&
                            <div className={styles['loading']}>
                                <Loading />
                            </div>
                        }
                        {!isLoading && icon &&
                            <div className={styles['icon']} onClick={handleIconClick}>
                                <Icon icon={icon} customSize />
                            </div>
                        }
                    </>
                }
            </div>
            <div className={styles['error']}>{error}</div>
            {passwordMeter &&
                <PasswordMeter password={value} strengthCallback={strength => passwordMeter(strength)} showRequirements={passwordMeterRequirements} />
            }
        </div>
    )
})

Input.displayName = 'Input';

export { Input };
