import { FC, Fragment } from 'react';

import styles from './ButtonsBar.module.scss';

interface ButtonsBarProps {
    left?: Array<JSX.Element | null>;
    right?: Array<JSX.Element | null>;
    wrap?: boolean;
    className?: string;
}

export const ButtonsBar: FC<ButtonsBarProps> = ({ left, right, wrap, className }) => {
    return (
        <div className={`${styles['container']} ${className ?? ''} ${wrap ? styles['wrap'] : ''}`}>
            {left?.length &&
                <div className={styles['left']}>
                    {left.map((item, i) => (<Fragment key={i}>{item}</Fragment>))}
                </div>
            }
            {right?.length &&
                <div className={styles['right']}>
                    {right.map((item, i) => (<Fragment key={i}>{item}</Fragment>))}
                </div>
            }
        </div>
    )
}
