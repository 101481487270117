import { FC } from 'react';
import { Icon } from '..';
import { IconSquareCheck, IconSquare } from '../../shared';

import styles from './Checkbox.module.scss';

interface CheckboxProps {
    checked?: boolean;
    label?: string;
    onClick?: () => void;
    disabled?: boolean;
    className?: string;
}

export const Checkbox: FC<CheckboxProps> = ({ checked = false, label, children, onClick, disabled, className = '' }) => {
    const handleOnClick = () => {
        if(disabled)
            return;
        
        onClick?.();
    }

    const containerClass = `
        ${styles['container']}
        ${className}
        ${disabled ? styles['disabled'] : ''}
        ${checked ? styles['checked'] : ''}
    `;
    return (
        <div className={containerClass} onClick={handleOnClick}>
            <div className={styles['icon']}>
                <Icon icon={checked ? IconSquareCheck : IconSquare } />
            </div>
            <div className={styles['content']}>
                {label ?? children}
            </div>
        </div>
    )
}
