import { addMinutes, addSeconds, differenceInMilliseconds, isEqual } from 'date-fns';
import { FC, useEffect, useRef, useState } from 'react';
import { Session } from '../entity';
import { Connect, ConnectProps, RootState } from '../infrastructure';
import { setSession } from '../redux/auth/Auth.operations';
import { useTabVisibility, useTimeout } from '../shared/hooks';

interface AuthHandlerProps extends ReduxProps {}
const AuthHandler: FC<AuthHandlerProps> = ({ session, setSession }) => {
    const isVisible = useTabVisibility();
    
    const expiresAt = useRef<Date | null>(null);

    const [refreshTimer, setRefreshTimer] = useState<number | null>(null);
    const [expireTimer, setExpireTimer] = useState<number | null>(null);
    const [retryRefreshTimer, setRetryRefreshTimer] = useState<number | null>(null);

    useTimeout(() => { refreshAuth(); }, refreshTimer);
    useTimeout(() => { clearAuth(); }, expireTimer);
    useTimeout(() => { refreshAuth(); }, retryRefreshTimer);

    useEffect(() => {
        handleAuth();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(isVisible)
            handleAuth();
        // eslint-disable-next-line
    }, [isVisible]);

    useEffect(() => {
        if(!expiresAt.current || !session) {
            handleAuth();
            return;
        }
        
        if(!isEqual(new Date(session.expiresAt), expiresAt.current)) {
            handleAuth();
            return;
        }
        // eslint-disable-next-line
    }, [session]);

    const handleAuth = (): void => {
        if(!session) {
            clearAuth();
            return;
        }

        const now = new Date();
        expiresAt.current = new Date(session.expiresAt);
        if(now > expiresAt.current) {
            clearAuth();
            return;
        }

        const millisecondsToRefresh = differenceInMilliseconds(addMinutes(expiresAt.current, -10), now);
        setRefreshTimer(millisecondsToRefresh);

        const millisecondsToExpire = differenceInMilliseconds(addSeconds(new Date(expiresAt.current), -10), now);
        setExpireTimer(millisecondsToExpire);
    }

    const refreshAuth = async (): Promise<void> => {
        // const response = await AuthService.Refresh(false);

        // if(response.failure || !response.body?.data?.token) {
        //     if(response.failureNetwork || response.failureServer) {
        //         const now = new Date();
        //         const millisecondsToRetry = differenceInMilliseconds(addMinutes(now, 1), now);
        //         setRetryRefreshTimer(millisecondsToRetry);
        //         return;
        //     }

        //     clearAuth();
        //     return;
        // }

        // setSession(Session.MakeSession(response.body?.data));
    }

    const clearAuth = () => {
        expiresAt.current = null;

        setRefreshTimer(null);
        setRetryRefreshTimer(null);

        setSession(null);
    }

    return null;
}

const mapState = ({ auth }: RootState) => ({
    session: auth.session,
});

const mapDispatch = {
    setSession: (session: Session | null) => setSession(session),
};

const connector = Connect(mapState, mapDispatch);
type ReduxProps = ConnectProps<typeof connector>;
const AuthHandlerConnected = connector(AuthHandler);
export { AuthHandlerConnected as AuthHandler };
