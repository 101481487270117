import { MessagesActionsConst } from './Messages.actions';

export interface MessagesState {
    unreadCount: number;
    resetUnreadCount: boolean;
}

const initialState: MessagesState = {
    unreadCount: 0,
    resetUnreadCount: false,
}

export const MessagesReducer = (state: MessagesState = initialState, { type, payload }: ReduxAction): MessagesState => {
    switch(type) {
        case MessagesActionsConst.SetUnreadCount:
            return { ...state, unreadCount: payload };
        
        case MessagesActionsConst.ResetUnreadCount:
            return { ...state, resetUnreadCount: payload };
        
        default:
            return state;
    }
}
