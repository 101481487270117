export const IconActivity = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <polyline points='22 12 18 12 15 21 9 3 6 12 2 12' />
    </g>
);

export const IconAlertCircle = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <circle cx='12' cy='12' r='10' />
        <line x1='12' y1='8' x2='12' y2='12' />
        <line x1='12' y1='16' x2='12.01' y2='16' />
    </g>
);

export const IconAlertTriangle = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <path d='M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z' />
        <line x1='12' y1='9' x2='12' y2='13' />
        <line x1='12' y1='17' x2='12.01' y2='17' />
    </g>
);

export const IconAlignCenter = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <line x1='18' y1='10' x2='6' y2='10' />
        <line x1='21' y1='6' x2='3' y2='6' />
        <line x1='21' y1='14' x2='3' y2='14' />
        <line x1='18' y1='18' x2='6' y2='18' />
    </g>
);

export const IconArchive = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <polyline points='21 8 21 21 3 21 3 8' />
        <rect x='1' y='3' width='22' height='5' />
        <line x1='10' y1='12' x2='14' y2='12' />
    </g>
);

export const IconBag = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <path d='M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z' />
        <line x1='3' y1='6' x2='21' y2='6' />
        <path d='M16 10a4 4 0 0 1-8 0' />
    </g>
);

export const IconBasket = (
    <g viewBox='0 0 576 512' fill='currentColor'>
        <path d='M576 216v16c0 13.255-10.745 24-24 24h-8l-26.113 182.788C514.509 462.435 494.257 480 470.37 480H105.63c-23.887 0-44.139-17.565-47.518-41.212L32 256h-8c-13.255 0-24-10.745-24-24v-16c0-13.255 10.745-24 24-24h67.341l106.78-146.821c10.395-14.292 30.407-17.453 44.701-7.058 14.293 10.395 17.453 30.408 7.058 44.701L170.477 192h235.046L326.12 82.821c-10.395-14.292-7.234-34.306 7.059-44.701 14.291-10.395 34.306-7.235 44.701 7.058L484.659 192H552c13.255 0 24 10.745 24 24zM312 392V280c0-13.255-10.745-24-24-24s-24 10.745-24 24v112c0 13.255 10.745 24 24 24s24-10.745 24-24zm112 0V280c0-13.255-10.745-24-24-24s-24 10.745-24 24v112c0 13.255 10.745 24 24 24s24-10.745 24-24zm-224 0V280c0-13.255-10.745-24-24-24s-24 10.745-24 24v112c0 13.255 10.745 24 24 24s24-10.745 24-24z' />
    </g>
);

export const IconCalendar = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <rect x='3' y='4' width='18' height='18' rx='2' ry='2' />
        <line x1='16' y1='2' x2='16' y2='6' />
        <line x1='8' y1='2' x2='8' y2='6' />
        <line x1='3' y1='10' x2='21' y2='10' />
    </g>
);

export const IconCar = (
    <g viewBox='0 0 1000 1000' fill='currentColor'>
        <g style={{ transform: 'scale(-1, 1) translate(-1000px, 0)' }}>
            <path d='M181.1,526.5c-40.8,0-73.9,33.1-73.9,73.9c0,3.1,0.3,6.2,0.7,9.3c4.6,36.5,35.6,64.6,73.3,64.6c38.7,0,70.4-29.7,73.6-67.6c0.2-2.1,0.3-4.2,0.3-6.3C255,559.6,221.9,526.5,181.1,526.5z M144.1,572.6l15.1,15.1c-1.2,2-2.1,4.2-2.7,6.5h-21.3C136.3,586.1,139.4,578.7,144.1,572.6z M135.1,606.9h21.4c0.6,2.3,1.5,4.4,2.7,6.4l-15.1,15.1C139.4,622.3,136.3,615,135.1,606.9z M174.7,646.4c-8-1.1-15.3-4.3-21.4-8.9l15-15c2,1.2,4.1,2,6.4,2.6V646.4z M174.7,575.8c-2.3,0.6-4.5,1.5-6.5,2.7l-15.1-15.1c6.2-4.7,13.5-7.8,21.6-8.9V575.8z M187.5,554.5c8.1,1.1,15.4,4.2,21.6,8.9L194,578.5c-2-1.2-4.2-2.1-6.5-2.7L187.5,554.5L187.5,554.5z M187.5,646.3V625c2.3-0.6,4.4-1.4,6.4-2.6l15,15C202.8,642.1,195.5,645.2,187.5,646.3z M218.1,628.5l-15.2-15.2c1.2-2,2.2-4.1,2.8-6.4h21.3C225.8,614.9,222.8,622.4,218.1,628.5z M205.7,594.1c-0.6-2.3-1.5-4.5-2.7-6.5l15.1-15.1c4.7,6.2,7.7,13.5,8.9,21.6L205.7,594.1L205.7,594.1z'/>
            <path d='M795.1,526.5c-40.8,0-74,33.1-74,73.9c0,3.1,0.3,6.2,0.7,9.3c4.6,36.5,35.6,64.6,73.3,64.6c38.7,0,70.4-29.7,73.6-67.6c0.2-2.1,0.3-4.2,0.3-6.3C869.1,559.6,836,526.5,795.1,526.5z M758.1,572.6l15.1,15.1c-1.2,2-2.1,4.2-2.7,6.5h-21.3C750.3,586.1,753.4,578.7,758.1,572.6z M749.1,606.9h21.4c0.6,2.3,1.5,4.4,2.7,6.4l-15.1,15.1C753.4,622.3,750.3,615,749.1,606.9z M788.7,646.4c-8-1.1-15.3-4.3-21.4-8.9l15-15c2,1.2,4.1,2,6.4,2.6V646.4z M788.7,575.8c-2.3,0.6-4.5,1.5-6.5,2.7l-15.1-15.1c6.2-4.7,13.5-7.8,21.6-8.9V575.8z M801.5,554.5c8.1,1.1,15.4,4.2,21.6,8.9l-15.1,15.1c-2-1.2-4.2-2.1-6.5-2.7V554.5z M801.5,646.3V625c2.3-0.6,4.4-1.4,6.4-2.6l15,15C816.8,642.1,809.5,645.2,801.5,646.3z M832.1,628.5L817,613.4c1.2-2,2.2-4.1,2.7-6.4H841C839.8,614.9,836.8,622.4,832.1,628.5z M819.7,594.1c-0.6-2.3-1.5-4.5-2.7-6.5l15.1-15.1c4.7,6.2,7.7,13.5,8.9,21.6L819.7,594.1L819.7,594.1z'/>
            <path d='M972.7,488.2v-54.7c0-8.8-6.4-16.3-15.1-17.6c-20.9-3.3-57.8-9.4-74.5-14.6c-23.8-7.3-115.9-46.3-147.8-53.6c-31.9-7.3-175.7-46.4-313.3,1.3c-23.3,8.1-128.1,58.2-189.7,84.2c-18.2,0.4-197.8,37.8-207.4,63.5c-9.6,25.7-12.6,38.1-14.3,44.7c-1.8,6.5,0,37.7,13.3,59.4c13.6,7.7,39.8,12.9,70.1,16.3c-0.3-1.8-0.7-3.7-1-5.5c-0.5-4-0.8-7.7-0.8-11.1c0-49,39.8-88.8,88.8-88.8c49,0,88.8,39.8,88.8,88.8c0,2.5-0.2,5-0.4,7.5c-0.5,5.5-1.5,10.8-2.9,16c0.1,0,0.2,0,0.2,0l440.8-8.9c-0.2-1.1-0.5-2.3-0.6-3.4c-0.5-4-0.8-7.6-0.8-11.1c0-49,39.8-88.8,88.8-88.8c49,0,88.8,39.8,88.8,88.8c0,0.2,0,0.3,0,0.5l1.9-0.2l75.4-14.2c0,0,28.9-15.4,28.9-57.6C990,500.8,972.7,488.2,972.7,488.2z M535.4,436.3l-164.6,5c3-24.7-11.6-30.2-11.6-30.2c65.4-59.4,197.3-61.4,197.3-61.4L535.4,436.3z M720.2,428.8l-150,5l13.6-84.1c77.5-1,121.8,16.1,121.8,16.1l22.1,35.7L720.2,428.8z M804.7,428.3h-41.8l-41.8-57.4c31.4,12.5,56.4,24.8,71.4,32.5C801.6,408.1,806.5,418.2,804.7,428.3z'/>
        </g>
    </g>
);

export const IconCard = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <rect x='1' y='4' width='22' height='16' rx='2' ry='2' />
        <line x1='1' y1='10' x2='23' y2='10' />
    </g>
);

export const IconCheck = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <polyline points='20 6 9 17 4 12' />
    </g>
);

export const IconCheckCircle = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <path d='M22 11.08V12a10 10 0 1 1-5.93-9.14' />
        <polyline points='22 4 12 14.01 9 11.01' />
    </g>
);

export const IconChevronUp = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <polyline points='17 11 12 6 7 11' />
    </g>
);

export const IconChevronRight = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <polyline points='9 18 15 12 9 6' />
    </g>
);

export const IconChevronsRight = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <polyline points='13 17 18 12 13 7' />
        <polyline points='6 17 11 12 6 7' />
    </g>
);

export const IconChevronDown = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <polyline points='6 9 12 15 18 9' />
    </g>
);

export const IconChevronLeft = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <polyline points='15 18 9 12 15 6' />
    </g>
);

export const IconCircle = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <circle cx='12' cy='12' r='10' />
    </g>
);

export const IconCircleFill = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <circle cx='12' cy='12' r='10' />
        <circle cx='12' cy='12' r='5' fill='currentColor' />
    </g>
);

export const IconClock = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <circle cx='12' cy='12' r='10' />
        <polyline points='12 6 12 12 16 14' />
    </g>
);

export const IconCopy = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <rect x='9' y='9' width='13' height='13' rx='2' ry='2' />
        <path d='M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1' />
    </g>
);

export const IconCornerDownLeft = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <polyline points='9 10 4 15 9 20' />
        <path d='M20 4v7a4 4 0 0 1-4 4H4' />
    </g>
);

export const IconCornerRightDown = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <polyline points='10 15 15 20 20 15' />
        <path d='M4 4h7a4 4 0 0 1 4 4v12' />
    </g>
)

export const IconCornerRightUp = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <polyline points='10 9 15 4 20 9' />
        <path d='M4 20h7a4 4 0 0 0 4-4V4' />
    </g>
);

export const IconDisc = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <circle cx='12' cy='12' r='10' />
        <circle cx='12' cy='12' r='3' />
    </g>
);

export const IconDollarSign = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <line x1='12' y1='1' x2='12' y2='23' />
        <path d='M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6' />
    </g>
);

export const IconDot = (
    <g viewBox='0 0 24 24' fill='currentColor'>
        <circle cx='50%' cy='50%' r='50%' />
    </g>
);

export const IconDownload = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <path d='M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4' />
        <polyline points='7 10 12 15 17 10' />
        <line x1='12' y1='15' x2='12' y2='3' />
    </g>
);

export const IconDownloadCloud = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <polyline points='8 17 12 21 16 17' />
        <line x1='12' y1='12' x2='12' y2='21' />
        <path d='M20.88 18.09A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.29' />
    </g>
);

export const IconEdit = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <path d='M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7' />
        <path d='M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z' />
    </g>
);

export const IconEditAlt = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <path d='M12 20h9' />
        <path d='M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z' />
    </g>
);

export const IconEmail = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <path d='M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z' />
        <polyline points='22,6 12,13 2,6' />
    </g>
);

export const IconEye = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <path d='M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z' />
        <circle cx='12' cy='12' r='3' />
    </g>
);

export const IconEyeOff = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <path d='M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24' />
        <line x1='1' y1='1' x2='23' y2='23' />
    </g>
);

export const IconFilter = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <polygon points='22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3' />
    </g>
);

export const IconFileText = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <path d='M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z' />
        <polyline points='14 2 14 8 20 8' />
        <line x1='16' y1='13' x2='8' y2='13' />
        <line x1='16' y1='17' x2='8' y2='17' />
        <polyline points='10 9 9 9 8 9' />
    </g>
);

export const IconGitBranch = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <line x1='6' y1='3' x2='6' y2='15' />
        <circle cx='18' cy='6' r='3' />
        <circle cx='6' cy='18' r='3' />
        <path d='M18 9a9 9 0 0 1-9 9' />
    </g>
);

export const IconHome = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <path d='M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z' />
        <polyline points='9 22 9 12 15 12 15 22' />
    </g>
);

export const IconImage = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <rect x='3' y='3' width='18' height='18' rx='2' ry='2' />
        <circle cx='8.5' cy='8.5' r='1.5' />
        <polyline points='21 15 16 10 5 21' />
    </g>
);

export const IconInfo = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <circle cx='12' cy='12' r='10' />
        <line x1='12' y1='16' x2='12' y2='12' />
        <line x1='12' y1='8' x2='12.01' y2='8' />
    </g>
);

export const IconInstagram = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <rect x='2' y='2' width='20' height='20' rx='5' ry='5' />
        <path d='M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z' />
        <line x1='17.5' y1='6.5' x2='17.51' y2='6.5' />
    </g>
);

export const IconLayers = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <polygon points='12 2 2 7 12 12 22 7 12 2' />
        <polyline points='2 17 12 22 22 17' />
        <polyline points='2 12 12 17 22 12' />
    </g>
);

export const IconLink = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <path d='M15 7h3a5 5 0 0 1 5 5 5 5 0 0 1-5 5h-3m-6 0H6a5 5 0 0 1-5-5 5 5 0 0 1 5-5h3' />
        <line x1='8' y1='12' x2='16' y2='12' />
    </g>
);

export const IconLock = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <rect x='3' y='11' width='18' height='11' rx='2' ry='2' />
        <path d='M7 11V7a5 5 0 0 1 10 0v4' />
    </g>
);

export const IconLogout = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <path d='M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4' />
        <polyline points='16 17 21 12 16 7' />
        <line x1='21' y1='12' x2='9' y2='12' />
    </g>
);

export const IconMaximize = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <polyline points='15 3 21 3 21 9' />
        <polyline points='9 21 3 21 3 15' />
        <line x1='21' y1='3' x2='14' y2='10' />
        <line x1='3' y1='21' x2='10' y2='14' />
    </g>
);

export const IconMapPin = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <path d='M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z' />
        <circle cx='12' cy='10' r='3' />
    </g>
);

export const IconMenu = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <line x1='3' y1='12' x2='21' y2='12' />
        <line x1='3' y1='6' x2='21' y2='6' />
        <line x1='3' y1='18' x2='21' y2='18' />
    </g>
);

export const IconMessage = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <path d='M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z' />
    </g>
);

export const IconMessageSquare = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <path d='M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z' />
    </g>
);

export const IconMinimize = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <path d='M8 3v3a2 2 0 0 1-2 2H3m18 0h-3a2 2 0 0 1-2-2V3m0 18v-3a2 2 0 0 1 2-2h3M3 16h3a2 2 0 0 1 2 2v3' />
    </g>
);

export const IconMinusCircle = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <circle cx='12' cy='12' r='10' />
        <line x1='8' y1='12' x2='16' y2='12' />
    </g>
);

export const IconNavigation = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <polygon points='12 2 19 21 12 17 5 21 12 2' />
    </g>
);

export const IconPackage = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <line x1='16.5' y1='9.4' x2='7.5' y2='4.21' />
        <path d='M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z' />
        <polyline points='3.27 6.96 12 12.01 20.73 6.96' />
        <line x1='12' y1='22.08' x2='12' y2='12' />
    </g>
);

export const IconPercent = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <line x1='19' y1='5' x2='5' y2='19' />
        <circle cx='6.5' cy='6.5' r='2.5' />
        <circle cx='17.5' cy='17.5' r='2.5' />
    </g>
);

export const IconPhone = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <path d='M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z' />
    </g>
);

export const IconPix = (
    <g viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg' fill='currentColor' fillRule='evenodd'>
        <path d='M393.072 391.897c-20.082 0-38.969-7.81-53.176-22.02l-77.069-77.067c-5.375-5.375-14.773-5.395-20.17 0l-76.784 76.786c-14.209 14.207-33.095 22.019-53.179 22.019h-9.247l97.521 97.52c30.375 30.375 79.614 30.375 109.988 0l97.239-97.238h-15.123zm-105.049 74.327c-8.55 8.53-19.93 13.25-32.05 13.25h-.02c-12.12 0-23.522-4.721-32.05-13.25l-56.855-56.855c7.875-4.613 15.165-10.248 21.758-16.84l63.948-63.948 64.23 64.23c7.637 7.66 16.188 14.013 25.478 18.952l-54.439 54.46zM310.958 22.78c-30.374-30.374-79.613-30.374-109.988 0l-97.52 97.52h9.247c20.082 0 38.97 7.834 53.178 22.02l76.784 76.785c5.57 5.592 14.622 5.57 20.17 0l77.069-77.068c14.207-14.187 33.094-22.02 53.176-22.02h15.123l-97.239-97.237zm6.028 96.346l-64.23 64.23-63.97-63.97c-6.593-6.592-13.86-12.206-21.736-16.818l56.853-56.877c17.69-17.645 46.476-17.668 64.121 0l54.44 54.461c-9.292 4.961-17.842 11.315-25.479 18.974h.001z' />
        <path d='M489.149 200.97l-58.379-58.377h-37.706c-13.838 0-27.394 5.635-37.185 15.426l-77.068 77.069c-7.202 7.18-16.623 10.77-26.067 10.77-9.443 0-18.885-3.59-26.066-10.77l-76.785-76.785c-9.792-9.814-23.346-15.427-37.207-15.427h-31.81L22.78 200.97c-30.374 30.375-30.374 79.614 0 109.988l58.095 58.074 31.81.021c13.86 0 27.416-5.635 37.208-15.426l76.784-76.764c13.925-13.947 38.208-13.924 52.133-.02l77.068 77.066c9.791 9.792 23.346 15.405 37.185 15.405h37.706l58.379-58.356c30.374-30.374 30.374-79.613 0-109.988zm-362.19 129.724c-3.763 3.786-8.942 5.917-14.273 5.917H94.302l-48.59-48.564c-17.689-17.69-17.689-46.476 0-64.143L94.3 175.296h18.385c5.331 0 10.51 2.154 14.295 5.918l74.74 74.74-74.761 74.74zm339.257-42.647l-48.848 48.87h-24.305c-5.309 0-10.508-2.155-14.251-5.92l-75.023-75.043 75.023-75.023c3.743-3.764 8.942-5.918 14.252-5.918h24.304l48.847 48.891c8.573 8.551 13.273 19.93 13.273 32.05 0 12.141-4.7 23.52-13.273 32.093z' />
    </g>
)

export const IconPlusCircle = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <circle cx='12' cy='12' r='10' />
        <line x1='12' y1='8' x2='12' y2='16' />
        <line x1='8' y1='12' x2='16' y2='12' />
    </g>
);

export const IconPrint = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <polyline points='6 9 6 2 18 2 18 9' />
        <path d='M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2' />
        <rect x='6' y='14' width='12' height='8' />
    </g>
);

export const IconPocket = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <path d='M4 3h16a2 2 0 0 1 2 2v6a10 10 0 0 1-10 10A10 10 0 0 1 2 11V5a2 2 0 0 1 2-2z' />
        <polyline points='8 10 12 14 16 10' />
    </g>
);

export const IconRefresh = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <polyline points='23 4 23 10 17 10' />
        <polyline points='1 20 1 14 7 14' />
        <path d='M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15' />
    </g>
);

export const IconSave = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <path d='M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z' />
        <polyline points='17 21 17 13 7 13 7 21' />
        <polyline points='7 3 7 8 15 8' />
    </g>
);

export const IconSearch = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <circle cx='11' cy='11' r='8' />
        <line x1='21' y1='21' x2='16.65' y2='16.65' />
    </g>
);

export const IconSend = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <line x1='22' y1='2' x2='11' y2='13' />
        <polygon points='22 2 15 22 11 13 2 9 22 2' />
    </g>
);

export const IconSettings = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <circle cx='12' cy='12' r='3' />
        <path d='M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z' />
    </g>
);

export const IconSmartphone = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <rect x='5' y='2' width='14' height='20' rx='2' ry='2' />
        <line x1='12' y1='18' x2='12.01' y2='18' />
    </g>
);

export const IconSquare = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <rect x='3' y='3' width='18' height='18' rx='2' ry='2' />
    </g>
);

export const IconSquareCheck = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <polyline points='9 11 12 14 22 4' />
        <path d='M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11' />
    </g>
);

export const IconStar = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <polygon points='12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2' />
    </g>
);

export const IconTag = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <path d='M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z' />
        <line x1='7' y1='7' x2='7.01' y2='7' />
    </g>
);

export const IconTarget = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <circle cx='12' cy='12' r='10' />
        <circle cx='12' cy='12' r='6' />
        <circle cx='12' cy='12' r='2' />
    </g>
);

export const IconTool = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <path d='M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z' />
    </g>
);

export const IconTrash = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <polyline points='3 6 5 6 21 6' />
        <path d='M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2' />
        <line x1='10' y1='11' x2='10' y2='17' />
        <line x1='14' y1='11' x2='14' y2='17' />
    </g>
);

export const IconTrendingDown = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <polyline points='23 18 13.5 8.5 8.5 13.5 1 6' />
        <polyline points='17 18 23 18 23 12' />
    </g>
)

export const IconTruck = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <rect x='1' y='3' width='15' height='13' />
        <polygon points='16 8 20 8 23 11 23 16 16 16 16 8' />
        <circle cx='5.5' cy='18.5' r='2.5' />
        <circle cx='18.5' cy='18.5' r='2.5' />
    </g>
);

export const IconUploadCloud = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <polyline points='16 16 12 12 8 16' />
        <line x1='12' y1='12' x2='12' y2='21' />
        <path d='M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3' />
        <polyline points='16 16 12 12 8 16' />
    </g>
);

export const IconUser = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <path d='M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2' />
        <circle cx='12' cy='7' r='4' />
    </g>
);

export const IconUsers = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <path d='M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2' />
        <circle cx='9' cy='7' r='4' />
        <path d='M23 21v-2a4 4 0 0 0-3-3.87' />
        <path d='M16 3.13a4 4 0 0 1 0 7.75' />
    </g>
);

export const IconX = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <line x1='18' y1='6' x2='6' y2='18' />
        <line x1='6' y1='6' x2='18' y2='18' />
    </g>
);

export const IconXCircle = (
    <g viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
        <circle cx='12' cy='12' r='10' />
        <line x1='15' y1='9' x2='9' y2='15' />
        <line x1='9' y1='9' x2='15' y2='15' />
    </g>
);

export const IconWhatsapp = (
    <g viewBox='0 0 24 24' fill='currentColor'>
        <path d='M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z' />
    </g>
);
