const prefix = 'MESSAGES';

export const MessagesActionsConst = {
    SetUnreadCount: `${prefix}_SET_UNREAD_COUNT`,
    ResetUnreadCount: `${prefix}_RESET_UNREAD_COUNT`,
};

export const MessagesActions = {
    setUnreadCount: (count: number): ReduxAction => ({
        type: MessagesActionsConst.SetUnreadCount,
        payload: count,
    }),

    resetUnreadCount: (reset: boolean): ReduxAction => ({
        type: MessagesActionsConst.ResetUnreadCount,
        payload: reset,
    }),
};
