import { useState, useEffect } from 'react';

export const useTabVisibility = (): boolean => {
    const [isVisible, setIsVisible] = useState<boolean>(true);

    useEffect(() => {
        const handler = () => {
            setIsVisible(document.visibilityState === 'visible');
        }

        document.addEventListener('visibilitychange', handler);

        return () => {
            document.removeEventListener('visibilitychange', handler);
        }
    }, []);

    return isVisible;
}
