export const Routes = {
    Dashboard: {
        Home: '/',

        Login: {
            Base: '/login',
            ForgotPassword: '/login/forgot-password',
            ResetPassword: '/login/reset-password',
        },

        Orders: {
            Base: '/orders',
            Filter: '/orders/filter',
            Import: '/orders/import',
            Details: '/orders/details',
        },

        Trips: {
            Base: '/trips',
            Edit: '/trips/edit',
        },

        Brokers: {
            Base: '/brokers',
        },

        MyTrips: {
            Base: '/my-trips',
            Details: '/my-trips/details',
        },

        Messages: {
            Base: '/messages',
            Conversation: '/messages/conversation',
        },

        Templates: {
            Base: '/templates',
            Edit: '/templates/edit',
        },

        Quotes: {
            Base: '/quotes',
            Details: '/quotes/details',
        },

        Coupons: {
            Base: '/coupons',
        },

        Payments: {
            Base: '/payments',
            Details: '/payments/details',
        },

        Settings: {
            Base: '/settings',
            Users: {
                Base: '/settings/users',
                Edit: '/settings/users/edit',
            },
            MyAccount: '/settings/my-account',
            ChangePassword: '/settings/change-password',
        },
    }
} as const;
