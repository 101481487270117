import { FC } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.min.css';
import { UtilDate } from '../../shared';
import { InputState } from '../../shared/hooks';
import { Input } from '../Input';

import styles from './InputCalendar.module.scss';

interface InputCalendarProps {
    state: InputState<Date | null>;
    stateSetter: (key: keyof InputState<Date | null>, value: any) => void;
    minDate: Date;
}
export const InputCalendar: FC<InputCalendarProps> = ({ state, stateSetter, minDate }) => {
    return (
        <div className={styles['container']}>
            <DatePicker
                customInput={
                    <Input
                        value={state.value}
                        error={state.error}
                    />
                }
                selected={state.innerValue}
                onChange={v => {
                    stateSetter('value', UtilDate.FormatDate_MM_DD_YYYY(v ?? new Date()));
                    stateSetter('innerValue', v);
                    stateSetter('error', '');
                }}
                minDate={minDate}
            />
        </div>
    )
}
