import { HttpMethods } from '../shared/constants';
import { ApiClient, ApiRequest, ApiResponse } from './ApiClient';

export const AuthService = {
    Authenticate: async (email: string, password: string): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.POST,
            resource: '/auth',
            body: { email, password },
        });

        return await ApiClient.ExecuteRequest(request);
    },
}
