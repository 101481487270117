import { useNavigate } from 'react-router-dom';
import { Util } from '..';

interface RedirectOptions {
    newTab?: boolean;
    scrollToTop?: boolean;
}

export const useRedirect = () => {
    const navigate = useNavigate();

    const redirect = (path: string, options: RedirectOptions = { newTab: false, scrollToTop: true }) => {
        if (path.includes('http') || path.includes('tel:') || path.includes('mailto:') || options.newTab) {
            const a = document.createElement('a');
            a.setAttribute('href', path);
            a.setAttribute('rel', 'noopener noreferrer');
            a.setAttribute('target', '_blank');
            a.click();
            return;
        }

        if(options.scrollToTop)
            Util.ScrollTo(0, false);

        navigate(path);
    }

    return redirect;
}
