import { createContext, FC, useState } from 'react';
import { Toast, ToastProps } from '../components';
import { ApiResponse } from '../services/ApiClient';
import { IconAlertCircle, IconCheckCircle } from '../shared';
import { HttpStatus } from '../shared/constants';

type ToastContextType = {
    setToast: (toast: ToastProps) => void;
    setToastFromResponse: (response: ApiResponse, successText?: string) => void;
}

export const ToastContext = createContext<ToastContextType>({ setToast: () => {}, setToastFromResponse: () => {} });

export const _setToastFromResponse = (response: ApiResponse, successText?: string): ToastProps | null => {
    if(response.failure) {
        let text = 'Unexpected error';
        let details: string[] | undefined = undefined;
        if(response.status !== HttpStatus.InternalServerError && response.body.message) {
            text = response.body.message;
            
            if(response.body.details?.length)
                details = response.body.details;
        }
        
        return {
            icon: IconAlertCircle,
            theme: 'red',
            text,
            details,
        }
    }

    if(successText) {
        return {
            icon: IconCheckCircle,
            text: successText,
            theme: 'green',
        }
    }

    return null;
}

export const ToastProvider: FC = ({ children }) => {
    const [toasts, setToasts] = useState<ToastProps[]>([]);

    const _setToast = (_toast: ToastProps) => {
        _toast.id = String(Math.random());
        setToasts(t => [ ...t, _toast ]);
    };

    const setToastFromResponse = (response: ApiResponse, successText?: string) => {
        const toast = _setToastFromResponse(response, successText);
        if(toast)
            _setToast(toast);
    }

    const handleOnClose = (id: string) => {
        setToasts(t => {
            const indexToRemove = t.findIndex(t => t.id === id);
            if(indexToRemove >= 0)
                t.splice(indexToRemove, 1);
            return [ ...t ];
        });
    };

    return (
        <ToastContext.Provider value={{ setToast: _setToast, setToastFromResponse }}>
            {children}
            <div style={{ position: 'fixed', bottom: 16, right: 16, zIndex: 122 }}>
            {toasts.map(t => (
                <Toast key={t.id} { ...t } onClose={id => handleOnClose(id)} />
            ))}
            </div>
        </ToastContext.Provider>
    )
}
