import { config } from '../config';
import { OrderContact, OrderEndOption, OrderEndType, OrderFilters, OrderPosition, OrderStatus } from '../entity';
import { HttpMethods } from '../shared/constants';
import { ApiClient, ApiRequest, ApiResponse } from './ApiClient';

interface ListOrdersParams {
    status?: OrderStatus[];
    skip?: number;
    all?: boolean;
}

interface FilterOrdersParams {
    term: string;
    context: OrderFilters;
}

interface EditPositionParams {
    endOption: OrderEndOption;
    position: OrderPosition;
    notes: string;
}

export const OrdersService = {
    ListOrders: async ({ status, skip, all }: ListOrdersParams): Promise<ApiResponse> => {
        const query = new URLSearchParams();
        if(status)
            status.forEach(s => query.append('orderStatus[]', s));
        if(skip)
            query.append('skip', String(skip));
        if(all)
            query.append('all', 'true');
        const queryString = query.toString();

        const request = new ApiRequest({
            method: HttpMethods.GET,
            resource: `/orders${queryString ? `?${queryString}` : ''}`,
            auth: config.Auth.Token(),
        });

        return await ApiClient.ExecuteRequest(request);
    },

    UpdateEndType: async (orderId: string, orderEndOption: OrderEndOption, orderEndType: OrderEndType): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.PATCH,
            resource: `/orders/${orderId}/orderEndType`,
            auth: config.Auth.Token(),
            body: { orderEndOption, orderEndType },
        });

        return await ApiClient.ExecuteRequest(request);
    },

    UpdatePosition: async (orderId: string, position: OrderPosition | null, positionNotes: string): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.PATCH,
            resource: `/orders/${orderId}/position`,
            auth: config.Auth.Token(),
            body: { position, positionNotes },
        });

        return await ApiClient.ExecuteRequest(request);
    },

    AssignDriverToOrder: async (orderId: string, driverId: string): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.PATCH,
            resource: `/orders/${orderId}/driver`,
            auth: config.Auth.Token(),
            body: { driverId },
        });

        return await ApiClient.ExecuteRequest(request);
    },

    SetOrderContact: async (orderId: string, contact: OrderContact): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.PATCH,
            resource: `/orders/${orderId}/contact`,
            auth: config.Auth.Token(),
            body: { contact },
        });

        return await ApiClient.ExecuteRequest(request);
    },

    GetOrdersSummary: async (): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.GET,
            resource: '/orders/summary',
            auth: config.Auth.Token(),
        });

        return await ApiClient.ExecuteRequest(request);
    },

    FilterOrders: async ({ term, context }: FilterOrdersParams): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.GET,
            resource: `/orders/filter?term=${term}&context=${context}`,
            auth: config.Auth.Token(),
        });

        return await ApiClient.ExecuteRequest(request);
    },

    SetOrderBroker: async (orderId: string, brokerId: string): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.PATCH,
            resource: `/orders/${orderId}/broker`,
            auth: config.Auth.Token(),
            body: { brokerId },
        });

        return await ApiClient.ExecuteRequest(request);
    },

    ChangeOrderStatus: async (orderId: string, status: OrderStatus): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.PATCH,
            resource: `/orders/${orderId}/status`,
            auth: config.Auth.Token(),
            body: { status },
        });

        return await ApiClient.ExecuteRequest(request);
    },

    EditPosition: async (orderId: string, params: EditPositionParams): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.PATCH,
            resource: `/orders/${orderId}/position`,
            auth: config.Auth.Token(),
            body: { ...params },
        });

        return await ApiClient.ExecuteRequest(request);
    },

    GetOrder: async (orderId: string): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.GET,
            resource: `/orders/${orderId}`,
            auth: config.Auth.Token(),
        });

        return await ApiClient.ExecuteRequest(request);
    },
}
