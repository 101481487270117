import { FC, PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Routes } from '../shared';
import { Connect, ConnectProps, RootState } from '../infrastructure';
import { UserRoles } from '../entity';
import { Content } from '../components';

interface RestrictedRouteHandlerProps extends ReduxProps {
    allowedRoles: UserRoles[];
}

const RestrictedRouteHandler: FC<PropsWithChildren<RestrictedRouteHandlerProps>> = ({ session, allowedRoles, children }) => {
    const location = useLocation();
    
    if(session) {
        if(allowedRoles.includes(session.role))
            return <>{children}</>;
        return <div>forbidden</div>;
    }
    
    const redirectTo = encodeURI(location.pathname);
    return <Navigate to={`${Routes.Dashboard.Login.Base}?redirectTo=${redirectTo}`} />;
}

const mapState = ({ auth }: RootState) => ({
    session: auth.session,
});

const connector = Connect(mapState);
type ReduxProps = ConnectProps<typeof connector>;
const RestrictedRouteHandlerConnected = connector(RestrictedRouteHandler);
export { RestrictedRouteHandlerConnected as RestrictedRouteHandler };
