import { useState } from 'react';

export interface InputState<T> {
    value: string;
    innerValue?: T;
    loading: boolean;
    disabled: boolean;
    error: string;
}

export const useInput = <T>({
    value = '',
    innerValue = undefined,
    loading = false,
    disabled = false,
    error = '',
}: Partial<InputState<T>>): [InputState<T>, (key: keyof InputState<T>, value: any) => void] => {
    const [state, _setState] = useState<InputState<T>>({
        value,
        innerValue,
        loading,
        disabled,
        error,
    });

    const setState = (key: keyof InputState<T>, value: any): void => {
        _setState(prevState => ({
            ...prevState,
            [key]: value,
        }));
    }

    return [state, setState];
}
