import { FC } from 'react';
import { useNavigate } from 'react-router';
import { Loading } from '..';

import styles from './Table.module.scss';

interface TableColProps {
    center?: boolean;
    right?: boolean;
    clamp?: boolean;
    className?: string;
}
const TableCol: FC<TableColProps> = ({ children, center = false, right = false, clamp = false, className = '' }) => {
    return (
        <td className={`${center ? styles['center'] : ''} ${right ? styles['right'] : ''} ${clamp ? styles['clamp'] : ''} ${className}`}>
            {children}
        </td>
    )
}

interface TableRowProps {
    link?: string;
    onClick?: () => void;
    className?: string;
}
const TableRow: FC<TableRowProps> = ({ link, children, onClick, className }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        if(link)
            navigate(link);
        onClick?.();
    }

    return (
        <tr className={`${className ?? ''} ${link ? styles['link'] : ''}`} onClick={handleClick}>
            {children}
        </tr>
    )
}

const TableHead: FC = ({ children }) => {
    return (
        <thead className={styles['table-head']}>{children}</thead>
    )
}

const TableBody: FC = ({ children }) => {
    return (
        <tbody>{children}</tbody>
    )
}

interface ITable {
    Col: typeof TableCol;
    Row: typeof TableRow;
    Head: typeof TableHead;
    Body: typeof TableBody;
}
interface TableProps {
    className?: string;
    disabled?: boolean;
    isLoading?: boolean;
}
const Table: ITable & FC<TableProps> = ({ children, className, disabled = false, isLoading = false }) => {
    return (
        <div className={`${styles['table']} ${className ?? ''} ${disabled ? styles['disabled'] : ''} ${isLoading ? styles['is-loading'] : ''}`}>
            <div className={styles['table-holder']}>
                <table>{children}</table>
            </div>
            <div className={styles['loading']}>
                <Loading />
            </div>
        </div>
    )
}

Table.Col = TableCol;
Table.Row = TableRow;
Table.Head = TableHead;
Table.Body = TableBody;
export { Table };
