import { Coordinates } from './Coordinates';

export class Location {
    locationSid: string;
    city: string;
    state: string;
    country: string;
    zip: string;
    coordinates: Coordinates;
    isCurrentLocation: boolean;

    address: string;

    constructor(init?: Partial<Location>) {
        Object.assign(this, init);
    }
}
