import { RefObject, useEffect } from 'react';

export const useOnOutsideClick = <T extends HTMLElement = HTMLElement>(ref: RefObject<T>, handler: (ev: MouseEvent | TouchEvent) => void) => {
    useEffect(() => {
        const listener = (ev: MouseEvent | TouchEvent) => {
            if(!ref.current || ref.current.contains(ev.target as Node))
                return;
            
            handler(ev);
        }

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, handler]);
}
