import { Session } from '../../entity';
import { AuthActionsConst } from './Auth.actions';

export interface AuthState {
    session: Session | null;
}

const initialState: AuthState = {
    session: null,
};

export const AuthReducer = (state: AuthState = initialState, { type, payload }: ReduxAction): AuthState => {
    switch(type) {
        case AuthActionsConst.SetSession:
            return { ...state, session: payload };
        
        default:
            return state;
    }
}
