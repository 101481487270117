import { createStore, applyMiddleware, combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';
import { AuthReducer } from '../redux/auth/Auth.reducer';
import { MessagesReducer } from '../redux/messages/Messages.reducer';
import { OrdersReducer } from '../redux/orders/Orders.reducer';

const authPersisConfig = { key: 'auth', storage };
const messagesPersistConfig = { key: 'messages', storage };
const ordersPersistConfig = { key: 'orders', storage };

const reducers = combineReducers({
    auth: persistReducer(authPersisConfig, AuthReducer),
    messages: persistReducer(messagesPersistConfig, MessagesReducer),
    orders: persistReducer(ordersPersistConfig, OrdersReducer),
});

const rootReducer = (state: any, action: ReduxAction) => {
    if(action.type === 'CLEAR_STORE')
        state = undefined;
    return reducers(state, action);
}

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
export const persistor = persistStore(store);
export const getState = () => store.getState();
export const dispatch = (action: any) => store.dispatch(action);
export type RootState = ReturnType<typeof store.getState>;

export const Connect = connect;
export type ConnectProps<T> = ConnectedProps<T>;
