import { config } from '../config';
import { OrderStatus } from '../entity';
import { HttpMethods } from '../shared/constants';
import { ApiClient, ApiRequest, ApiResponse } from './ApiClient';

export const SyncService = {
    SyncOrders: async (status: OrderStatus): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.POST,
            resource: `/sync/orders?status=${status}`,
            auth: config.Auth.Token(),
        });

        return await ApiClient.ExecuteRequest(request);
    },

    ResyncOrders: async (): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.PATCH,
            resource: `/sync/orders`,
            auth: config.Auth.Token(),
        });

        return await ApiClient.ExecuteRequest(request);
    },

    ResyncOrder: async (sdId: string): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.PATCH,
            resource: `/sync/orders/${sdId}`,
            auth: config.Auth.Token(),
        });

        return await ApiClient.ExecuteRequest(request);
    },

    ImportOrder: async (number: string): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.POST,
            resource: `/sync/orders/import`,
            auth: config.Auth.Token(),
            body: { number },
        });

        return await ApiClient.ExecuteRequest(request);
    },
}
