import { FC, useState } from 'react';
import { ErrorLoading, Loading } from '..';

import styles from './Content.module.scss';

export interface ContentHandler {
    loading: boolean;
    error: boolean;
    empty: boolean;
    refreshing: boolean;
    noContent: boolean;
}

export const useContentHandler = ({
    loading = true,
    error = false,
    empty = false,
    refreshing = false,
    noContent = false,
}: Partial<ContentHandler>): [ContentHandler, <K extends keyof ContentHandler>(key: K, value: ContentHandler[K]) => void] => {
    const [state, _setState] = useState<ContentHandler>({
        loading,
        error,
        empty,
        refreshing,
        noContent,
    });

    const setState = <K extends keyof ContentHandler>(key: K, value: ContentHandler[K]): void => {
        _setState(prev => ({
            ...prev,
            [key]: value,
        }));
    }

    return [state, setState];
}

interface ContentProps {
    handler: ContentHandler;
    errorAction?: () => void;
    className?: string;
    disabled?: boolean;
    loadingComponent?: JSX.Element;
}

export const Content: FC<ContentProps> = ({ handler, errorAction, className = '', children, disabled = false, loadingComponent = null }) => {
    const { loading, error, empty, refreshing, noContent } = handler;

    return (
        <div className={`${styles['container']} ${refreshing ? styles['refreshing'] : ''} ${className} ${disabled ? styles['disabled'] : ''}`}>
            {
                loading ? (loadingComponent ?? <div className={styles['loading']}><Loading /></div>) :
                error ? <ErrorLoading buttonAction={errorAction} /> :
                empty ? <div className={styles['empty']}>No content found</div> :
                noContent ? <div className={styles['no-content']}>The requested content does not exist or could not be found.</div> :
                children
            }
        </div>
    )
}
