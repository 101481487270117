import { config } from '../config';
import { UserRoles, UserStatus } from '../entity';
import { HttpMethods } from '../shared/constants';
import { ApiClient, ApiRequest, ApiResponse } from './ApiClient';

interface CreateUserParams {
    role: UserRoles;
    name: string;
    email: string;
    phone: string;
    password: string;
    passwordConfirmation: string;
}

interface EditUserParams {
    role?: UserRoles;
    name: string;
    email: string;
    phone: string;
}

interface ChangePasswordParams {
    password: string;
    newPassword: string;
    newPasswordConfirmation: string;
}

interface UsePasswordRecoveryParams {
    id: string;
    token: string;
    newPassword: string;
    newPasswordConfirmation: string;
}

export const UsersService = {
    CreateUser: async (params: CreateUserParams): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.POST,
            resource: '/users',
            auth: config.Auth.Token(),
            body: { ...params },
        });

        return await ApiClient.ExecuteRequest(request);
    },

    ListUsers: async (userRole?: UserRoles): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.GET,
            resource: `/users${userRole ? `?role=${userRole}` : ''}`,
            auth: config.Auth.Token(),
        });

        return await ApiClient.ExecuteRequest(request);
    },

    GetUser: async (userId: string): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.GET,
            resource: `/users/${userId}`,
            auth: config.Auth.Token(),
        });

        return await ApiClient.ExecuteRequest(request);
    },

    EditUser: async (userId: string, params: EditUserParams): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.PUT,
            resource: `/users/${userId}`,
            auth: config.Auth.Token(),
            body: { ...params },
        });

        return await ApiClient.ExecuteRequest(request);
    },

    ChangePassword: async (userId: string, params: ChangePasswordParams): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.PATCH,
            resource: `/users/${userId}/password`,
            auth: config.Auth.Token(),
            body: { ...params },
        });

        return await ApiClient.ExecuteRequest(request);
    },

    CreatePasswordRecovery: async (email: string): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.POST,
            resource: '/users/password-recovery',
            body: { email },
        });

        return await ApiClient.ExecuteRequest(request);
    },

    ValidatePasswordRecovery: async (id: string, token: string): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.GET,
            resource: `/users/password-recovery?id=${id}&token=${token}`,
        });

        return await ApiClient.ExecuteRequest(request);
    },

    UsePasswordRecovery: async (params: UsePasswordRecoveryParams): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.PATCH,
            resource: '/users/password-recovery',
            body: { ...params },
        });

        return await ApiClient.ExecuteRequest(request);
    },

    ChangeUserStatus: async (userId: string, status: UserStatus): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.PATCH,
            resource: '/users/status',
            auth: config.Auth.Token(),
            body: { userId, status },
        });

        return await ApiClient.ExecuteRequest(request);
    }
}
