import { config } from '../config';
import { HttpMethods } from '../shared/constants';
import { ApiClient, ApiRequest, ApiResponse } from './ApiClient';

interface CreateBrokerParams {
    name: string;
    email: string;
    phone: string;
}

interface EditBrokerParams {
    email: string;
    phone: string;
}

export const BrokersService = {
    ListBrokers: async (): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.GET,
            resource: '/brokers',
            auth: config.Auth.Token(),
        });

        return await ApiClient.ExecuteRequest(request);
    },

    CreateBroker: async (params: CreateBrokerParams): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.POST,
            resource: '/brokers',
            auth: config.Auth.Token(),
            body: { ...params },
        });

        return await ApiClient.ExecuteRequest(request);
    },

    EditBroker: async (brokerId: string, params: EditBrokerParams): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.PATCH,
            resource: `/brokers/${brokerId}`,
            auth: config.Auth.Token(),
            body: { ...params },
        });

        return await ApiClient.ExecuteRequest(request);
    },
} as const;
