import { ReactNode } from 'react';
import { CardBrandsIcons } from '../shared/CardBrandsIcons';
import { Quote } from './Quote';
import { Transaction } from './Transaction';

export enum PaymentStatus {
    Created = 'created',
    Canceled = 'canceled',
    Processing = 'processing',
    Paid = 'paid',
    Error = 'error',
    Refunded = 'refunded',
}

export enum PaymentPosition {
    Active = 'active',
    Archived = 'archived',
};

export class Payment {
    paymentId: string;
    number: number;
    status: PaymentStatus;

    quoteId: string | null;
    quote: Quote | null;

    description: string;
    amount: number;

    name: string;
    email: string;
    phone: string;

    createdBy: string | null;
    createdAt: Date;
    updatedAt: Date | null;

    position: PaymentPosition;

    transactions: Array<Transaction>;

    constructor(init?: Partial<Payment>) {
        Object.assign(this, init);
    }

    public static Make(obj: any): Payment {
        return new Payment({
            paymentId: obj.paymentId,
            number: obj.number,
            status: obj.status,

            quoteId: obj.quoteId ?? null,
            quote: obj.quote ? new Quote(obj.quote) : null,

            description: obj.description,
            amount: obj.amount,

            name: obj.name,
            email: obj.email,
            phone: obj.phone,

            createdBy: obj.createdBy ?? null,
            createdAt: new Date(obj.createdAt),
            updatedAt: obj.updatedAt ? new Date(obj.updatedAt) : null,

            position: obj.position,

            transactions: obj.transactions ? obj.transactions.map((t: any) => Transaction.Make(t)) : [],
        });
    }
}

export enum CardBrands {
    Visa = 'visa',
    VisaElectron = 'visaelectron',
    Mastercard = 'mastercard',
    Maestro = 'maestro',
    Amex = 'amex',
    DinersClub = 'dinersclub',
    Discover = 'discover',
    Elo = 'elo',
    Hipercard = 'hipercard',
    Jcb = 'jcb',
}

export class Card {
    number: string;
    name: string;
    expiration: string;
    cvc: string;
    brand: CardBrands;

    constructor(init?: Partial<Card>) {
        Object.assign(this, init);
    }
}

export namespace CardBrands {
    export const getLogo = (brand: CardBrands): ReactNode => {
        console.log({ brand });
        switch(brand) {
            case CardBrands.Amex:
                return CardBrandsIcons.Amex;
            case CardBrands.DinersClub:
                return CardBrandsIcons.DinersClub;
            case CardBrands.Discover:
                return CardBrandsIcons.Discover;
            case CardBrands.Jcb:
                return CardBrandsIcons.Jcb;
            case CardBrands.Maestro:
                return CardBrandsIcons.Maestro;
            case CardBrands.Mastercard:
                return CardBrandsIcons.Mastercard;
            case CardBrands.Visa:
            case CardBrands.VisaElectron:
                return CardBrandsIcons.Visa;
            default:
                return null;
        }
    }
}
