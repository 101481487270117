import { Dispatch } from 'redux';
import { MessagesActions } from './Messages.actions';

export const setMessagesUnreadCount = (count: number) => (dispatch: Dispatch) => {
    dispatch(MessagesActions.setUnreadCount(count));
}

export const resetMessagesUnreadCount = (reset: boolean) => (dispatch: Dispatch) => {
    dispatch(MessagesActions.resetUnreadCount(reset));
}
