import { FC, useState } from 'react';
import { Button, Icon, Skeleton } from '..';
import { IconAlertCircle, IconChevronLeft, IconRefresh } from '../../shared';
import { useRedirect } from '../../shared/hooks';
import { Helmet } from 'react-helmet';

import styles from './Page.module.scss';

interface ITitle {
    label: string;
    link?: string;
}

interface TitleProps {
    titles: Array<ITitle>;
    obs?: string;
}

export const Title: FC<TitleProps> = ({ titles , obs = null }) => {
    const redirect = useRedirect();
    const backTo = titles.length > 1 ? [ ...titles ].reverse().find(t => t.link)?.link : null;
    const title = titles[0].label;

    return (
        <>
        <Helmet>
            <title>{title} - CT4U Dashboard</title>
        </Helmet>

        <div className={styles['title']}>
            <div className={styles['title-main']}>
                {backTo &&
                    <div className={styles['title-back']} onClick={() => redirect(backTo)}>
                        <Icon icon={IconChevronLeft} />
                    </div>
                }
                <div className={styles['title-list']}>
                {titles.map(({ label, link }, i) => {
                    const hasLink = !!link;
                    const itemClass = `${styles['title-item']} ${hasLink ? styles['has-link'] : ''}`;
                    return (
                        <div className={itemClass} onClick={() => hasLink && redirect(link)} key={i}>
                            {label}
                        </div>
                    )
                })}
                </div>
            </div>
            {obs && <div className={styles['title-obs']}>{obs}</div>}
        </div>
        </>
    )
}

interface ErrorLoadingProps {
    buttonAction?: () => void;
}

export const ErrorLoading: FC<ErrorLoadingProps> = ({ buttonAction }) => (
    <div className={styles['error-loading']}>
        <div className={styles['error-loading-holder']}>
            <div className={styles['error-loading-icon']}>
                <Icon icon={IconAlertCircle} customSize />
            </div>
            <div className={styles['error-loading-title']}>Unexpected error</div>
            <div className={styles['error-loading-text']}>The requested content could not be processed at this time. Try again later.</div>
            {buttonAction && <div className={styles['error-loading-button']}><Button label={'Try again'} icon={IconRefresh} onClick={buttonAction} /></div>}
        </div>
    </div>
)

interface PageProps {
    className?: string;
    titles: Array<ITitle>;
    pageHandler?: PageHandler;
    emptyIgnore?: JSX.Element;
    errorAction?: () => void;
    staticContent?: JSX.Element;
}

export const Page: FC<PageProps> = ({
    className = '',
    titles,
    pageHandler = { loading: false, error: false, ready: true, empty: false, noContent: false, refreshing: false },
    emptyIgnore = null,
    errorAction,
    staticContent = null,
    children,
}) => {
    const { loading, error, ready, empty, noContent, refreshing } = pageHandler;

    const getContent = () => {
        if(error)
            return <ErrorLoading buttonAction={errorAction} />;
        
        if(loading)
            return <Skeleton />;
        
        const _emptyIgnore = emptyIgnore ? <div className={styles['empty-ignore']}>{emptyIgnore}</div> : null;
        if(empty || ready) {
            return (
                <>
                    {_emptyIgnore}
                    {
                    empty ? <div className={styles['page-empty']}>No records yet</div> :
                    noContent ? <div className={styles['page-empty']}>The requested content was not found or doesn't exist</div> :
                    children
                    }
                </>
            )
        }

        return null;
    }
    
    const containerClass = `${styles['page']} ${className} ${refreshing ? styles['is-refreshing'] : ''}`;
    return (
        <div className={containerClass}>
            <Title titles={titles} />
            {!!staticContent && <div className={styles['static']}>{staticContent}</div>}
            <div className={styles['content']}>
                {getContent()}
            </div>
        </div>
    )
}

interface PageHandler {
    loading: boolean;
    error: boolean;
    ready: boolean;
    empty: boolean;
    noContent: boolean;
    refreshing: boolean;
}

export const usePageHandler = ({
    loading = true,
    error = false,
    ready = true,
    empty = false,
    noContent = false,
    refreshing = false,
}: Partial<PageHandler>): [PageHandler, <K extends keyof PageHandler>(key: K, value: PageHandler[K]) => void] => {
    const [state, _setState] = useState<PageHandler>({
        loading,
        error,
        ready,
        empty,
        noContent,
        refreshing,
    });

    const setState = <K extends keyof PageHandler>(key: K, value: PageHandler[K]): void => {
        _setState(prev => ({
            ...prev,
            [key]: value,
        }));
    }

    return [state, setState];
}
