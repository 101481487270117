import { OrdersSummary } from '../../entity';
import { OrdersActionsConstants } from './Orders.actions';

export interface OrdersState {
    newOrdersCount: number;
    summary: OrdersSummary | null;
}

const initialState: OrdersState = {
    newOrdersCount: 0,
    summary: null,
}

export const OrdersReducer = (state: OrdersState = initialState, { type, payload }: ReduxAction): OrdersState => {
    switch(type) {
        case OrdersActionsConstants.SetNewOrdersCount:
            return { ...state, newOrdersCount: payload };
        
        case OrdersActionsConstants.SetOrdersSummary:
            return { ...state, summary: payload };
        
        default:
            return state;
    }
}
