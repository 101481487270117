import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';

import styles from './Drawer.module.scss';

interface DrawerProps {
    isOpen: boolean;
    onClose: () => void;
}
export const Drawer: FC<PropsWithChildren<DrawerProps>> = ({ isOpen: _isOpen, onClose, children }) => {
    const backdropRef = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState<boolean>(_isOpen);
    const [isClosing, setIsClosing] = useState<boolean>(false);

    useEffect(() => {
        backdropRef.current?.addEventListener('touchstart', ev => ev.stopPropagation(), { passive: true });
        backdropRef.current?.addEventListener('touchmove', ev => ev.stopPropagation(), { passive: true });
        backdropRef.current?.addEventListener('touchend', ev => ev.stopPropagation(), { passive: true });
    }, []);

    useEffect(() => {
        document.body.style.overflow = _isOpen ? 'hidden' : 'auto';

        if(_isOpen)
            setIsOpen(true);
        else if(!_isOpen && isOpen && !isClosing)
            handleOnClose();
    }, [_isOpen]);

    if(!isOpen)
        return null;

    const handleOnClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            onClose();
            setIsOpen(false);
            setIsClosing(false);
        }, 500);
    }

    const _class = `
        ${isOpen ? styles['is-open'] : ''}
        ${isClosing ? styles['is-closing'] : ''}
    `;
    return (
        <>
        <div className={`${styles['backdrop']} ${_class}`} onClick={handleOnClose} ref={backdropRef} />
        <div className={`${styles['container']} ${_class}`} onClick={ev => ev.stopPropagation()}>
            <div className={styles['content']}>
                {children}
            </div>
        </div>
        </>
    )
}
