import { config } from '../config';
import { HttpMethods } from '../shared/constants';
import { ApiClient, ApiRequest } from './ApiClient';

export const TemplatesService = {
    CreateTemplate: async (name: string, content: string) => {
        const request = new ApiRequest({
            method: HttpMethods.POST,
            resource: '/templates',
            auth: config.Auth.Token(),
            body: { name, content },
        });

        return await ApiClient.ExecuteRequest(request);
    },

    ListTemplates: async () => {
        const request = new ApiRequest({
            method: HttpMethods.GET,
            resource: '/templates',
            auth: config.Auth.Token(),
        });

        return await ApiClient.ExecuteRequest(request);
    },

    GetTemplate: async (templateId: string) => {
        const request = new ApiRequest({
            method: HttpMethods.GET,
            resource: `/templates/${templateId}`,
            auth: config.Auth.Token(),
        });

        return await ApiClient.ExecuteRequest(request);
    },

    UpdateTemplate: async (templateId: string, name: string, content: string) => {
        const request = new ApiRequest({
            method: HttpMethods.PUT,
            resource: `/templates/${templateId}`,
            auth: config.Auth.Token(),
            body: { name, content },
        });

        return await ApiClient.ExecuteRequest(request);
    },

    DeleteTemplate: async (templateId: string) => {
        const request = new ApiRequest({
            method: HttpMethods.DELETE,
            resource: `/templates/${templateId}`,
            auth: config.Auth.Token(),
        });

        return await ApiClient.ExecuteRequest(request);
    }
}
