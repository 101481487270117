import { config } from '../config';
import { PaymentPosition } from '../entity';
import { HttpMethods } from '../shared/constants';
import { ApiClient, ApiRequest, ApiResponse } from './ApiClient';

interface CreatePaymentParams {
    name: string;
    email: string;
    phone: string;
    description: string;
    amount: number;
}

export const PaymentsService = {
    ListPayments: async (skip: number, position: PaymentPosition): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.GET,
            resource: `/payments?skip=${skip}&position=${position}`,
            auth: config.Auth.Token(),
        });

        return await ApiClient.ExecuteRequest(request);
    },

    CreatePayment: async (params: CreatePaymentParams): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.POST,
            resource: `/payments`,
            auth: config.Auth.Token(),
            body: { ...params },
        });

        return await ApiClient.ExecuteRequest(request);
    },

    ArchivePayment: async (paymentId: string): Promise<ApiResponse> => {
        const request = new ApiRequest({
            method: HttpMethods.PATCH,
            resource: `/payments/${paymentId}/archive`,
            auth: config.Auth.Token(),
        });

        return await ApiClient.ExecuteRequest(request);
    },
} as const;
