import { useRef } from 'react';

export const useConstructor = (callback = () => {}) => {
    const hasBeenCalled = useRef(false);

    if(hasBeenCalled.current)
        return;
    
    callback();
    hasBeenCalled.current = true;
}
