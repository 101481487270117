import { getState } from './infrastructure';

//@ts-ignore
const { env } = window;

export const config = {
    Env: String(env.ENV),
    
    Api: {
        Base: String(env.API_BASE),
        Path: String(env.API_PATH),
        Url: String(env.API_URL),
    },

    Auth: {
        Token: (): string => getState().auth.session?.token ?? '',
    },

    Uploads: {
        ServiceKey: String(env.UPLOADS_SERVICE_KEY),
        ServiceName: String(env.UPLOADS_SERVICE_NAME),
        Api: String(env.UPLOADS_API_URL),
        Files: String(env.UPLOADS_FILES_URL),

        File: (file: string): string => `${config.Uploads.Files}${file}`,
        Image: (file: string): string => `${config.Uploads.Files}images/${file}`,
        Thumb: (file: string): string => `${config.Uploads.Files}images/thumbs/${file}`,
    },
} as const;
