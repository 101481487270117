import { OrderContact } from './Order';
import { Trip } from './Trip';
import { User } from './User';

export class Message {
    messageId: string;
    phone: string;
    
    userId: string | null;
    orderContactId: string | null;
    tripId: string;

    body: string;
    media: string[] | null;
    createdAt: Date;
    messageRead: boolean;

    name: string;

    user: User | null;
    orderContact: OrderContact | null;
    trip: Trip | null;

    constructor(init?: Partial<Message>) {
        Object.assign(this, init);
    }

    public static Make(obj: any): Message {
        const m = new Message();
        Object.assign(m, obj);

        m.createdAt = new Date(m.createdAt);
        m.user = m.user ? User.Make(m.user) : null;
        m.orderContact = m.orderContact ? OrderContact.Make(m.orderContact) : null;
        m.trip = m.trip ? Trip.Make(m.trip) : null;

        return m;
    }
}

export enum MessageFilters {
    TripName = 'trip-name',
    NameOrPhone = 'name-or-phone',
}
