import { OrderEndOption } from '.';
import { Driver } from './Driver';
import { Order, OrderStatus } from './Order';

export class TripRoute {
    orderId: string;
    endOption: OrderEndOption;
}

export enum TripStatus {
    Active = 'active',
    Archived = 'archived',
    Deleted = 'deleted',
}

export class Trip {
    tripId: string;
    name: string;
    driverId: string;

    ordersIds: string[];
    route: TripRoute[];

    orders: Order[];
    driver: Driver;

    createdBy: string;
    createdAt: Date;
    updatedBy: string | null;
    updatedAt: Date | null;

    status: TripStatus;

    constructor(init?: Partial<Trip>) {
        Object.assign(this, init);
    }

    public static Make(obj: any): Trip {
        const trip = new Trip();
        Object.assign(trip, obj);
        
        if(trip.orders)
            trip.orders = trip.orders.map(o => Order.Make(o));
        trip.driver = Driver.Make(trip.driver);
        trip.createdAt = new Date(trip.createdAt);
        trip.updatedAt = trip.updatedAt ? new Date(trip.updatedAt) : null;

        return trip;
    }
}

export class TripOrdersStatus {
    orderStatus: OrderStatus;
    count: number;

    constructor(init?: Partial<TripOrdersStatus>) {
        Object.assign(this, init);
    }

    public static Make(obj: any): TripOrdersStatus {
        return new TripOrdersStatus({
            orderStatus: obj.orderStatus,
            count: Number(obj.count),
        });
    }
}

export class TripSummary {
    tripId: string;
    name: string;
    
    driverId: string;
    driverName: string;

    ordersIds: string[];
    ordersStatus: TripOrdersStatus[];

    status: TripStatus;

    constructor(init?: Partial<TripSummary>) {
        Object.assign(this, init);
    }

    public static Make(obj: any): TripSummary {
        return new TripSummary({
            tripId: obj.tripId,
            name: obj.name,

            driverId: obj.driverId,
            driverName: obj.driverName,

            ordersIds: obj.ordersIds,
            ordersStatus: obj.ordersStatus?.map((os: any) => TripOrdersStatus.Make(os)),

            status: obj.status,
        });
    }
}
