import { FC, ReactNode } from 'react';

import styles from './Form.module.scss';

interface FormRowProps {
    className?: string;
}
const FormRow: FC<FormRowProps> = ({ className = '', children }) => {
    return (
        <div className={`${styles['form-row']} ${className}`}>{children}</div>
    )
}

interface FormItemProps {
    title?: string;
    value?: any;
    obs?: ReactNode;
    obsRight?: boolean;
    className?: string;
    size?: 'smaller' | 'small' | 'large' | 'larger';
    childrenAsValue?: boolean;
}
const FormItem: FC<FormItemProps> = ({ title, value = null, obs, obsRight = false, children, className = '', size = '', childrenAsValue = false }) => {
    return (
        <div className={`${styles['form-item']} ${className} ${size ? styles[`size-${size}`] : ''} ${childrenAsValue ? styles['children-as-value'] : ''}`}>
            <div className={styles['form-item-top']}>
                {title && <div className={styles['form-item-title']}>{title}</div>}
            </div>

            {children && <div className={styles['form-item-content']}>{children}</div>}
            {value && <div className={styles['form-item-value']}>{value}</div>}

            {obs && <div className={`${styles['form-item-obs']} ${obsRight ? styles['form-item-obs-right'] : ''}`}>{obs}</div>}
        </div>
    )
}

interface FormGroupProps {
    label: string;
    className?: string;
}

const FormGroup: FC<FormGroupProps> = ({ label, className = '', children }) => (
    <div className={`${styles['form-group']} ${className}`}>
        <div className={styles['form-group-label']}>{label}</div>
        <div className={styles['form-group-content']}>{children}</div>
    </div>
)

interface FormButtonsProps {
    className?: string;
}
const FormButtons: FC<FormButtonsProps> = ({ children, className = '' }) => <div className={`${styles['form-buttons']} ${className}`}>{children}</div>;

interface IForm {
    Row: typeof FormRow;
    Item: typeof FormItem;
    Buttons: typeof FormButtons;
    Group: typeof FormGroup;
}

export const Form: IForm = {
    Row: FormRow,
    Item: FormItem,
    Buttons: FormButtons,
    Group: FormGroup,
};
