import { FC, useEffect, useState } from 'react';
import { usePrevious } from '../../shared/hooks';

import styles from './Modal.module.scss';

export interface ModalProps {
    isOpen: boolean;
    closeOnBackdropClick?: boolean;
    mobileFullScreen?: boolean;
    closeCallback?: () => void;
    customSize?: boolean;
}

export const Modal: FC<ModalProps> = ({
    isOpen = false,
    closeOnBackdropClick = true,
    mobileFullScreen = false,
    closeCallback,
    customSize = false,
    children,
}) => {
    const [isClosing, setIsClosing] = useState<boolean>(false);
    const prevIsClosing = usePrevious<boolean>(isClosing);

    useEffect(() => {
        if(!isOpen && prevIsClosing)
            setIsClosing(true);
        
        // eslint-disable-next-line
    }, [isOpen]);

    isClosing && setTimeout(() => setIsClosing(false), 250);

    const backdropClass = `
        ${styles['backdrop']}
        ${isOpen ? styles['open'] : ''}
        ${mobileFullScreen ? styles['mobile-fullscreen'] : ''}
        ${isClosing ? styles['is-closing'] : ''}
    `;
    const contentClass = `${styles['content']} ${customSize ? styles['custom-size'] : ''}`;

    return (
        <div className={backdropClass} onClick={() => closeOnBackdropClick && closeCallback?.()}>
            <div className={contentClass} onClick={ev => ev.stopPropagation()}>
                <div className={styles['content-holder']}>{children}</div>
            </div>
        </div>
    )
}
