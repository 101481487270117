import { Session } from '../../entity';

const prefix = 'AUTH';

export const AuthActionsConst = {
    SetSession: `${prefix}_SET_SESSION`,
};

export const AuthActions = {
    setSession: (session: Session | null): ReduxAction => ({
        type: AuthActionsConst.SetSession,
        payload: session,
    }),
}
